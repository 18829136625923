import {displayMessage, getAPI, interpolate, makeFileURL, makeURL, postAPI} from "../../utils/common";
import {
    ACCEPT_PAYMENT,
    ALL_PRACTICE,
    BED_PACKAGES,
    BOOK_SEAT,
    CHECK_SEAT_AVAILABILITY,
    DISEASE_LIST,
    FILE_UPLOAD_API,
    MEDICINE_PACKAGES
} from "../../constants/api";
import React from "react";
import {
    Affix,
    Avatar,
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Icon,
    Input,
    message,
    Radio,
    Row,
    Select,
    Spin,
    Steps,
    Table,
    Upload
} from "antd";
import moment from "moment";
import {SUCCESS_MSG_TYPE} from "../../constants/dataKeys";
import {RAZORPAY_KEY} from "../../config/connect";

export default class BedandMedicineBookingDynamicForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinicsList: [],
            packages: [],
            medicinePackage: [],
            selectedMedicinePackages: {},
            choosePkg: [],
            diseases: [],
            rest_diseases: []
        }
    }

    componentDidMount() {
        this.loadClinics();
    }

    setClinic = (id) => {
        let that = this;

        this.setState({
            currentClinic: id
        }, function () {
            that.loadPackages();
            // that.loadPaymentModes();
            that.loadMedicinePackages();
            that.loadDiseases();
        })
    }
    loadPackages = () => {
        let that = this;
        this.setState({
            loadingPackages: true
        })
        let successFn = function (data) {
            that.setState({
                loadingPackages: false,
                packages: data
            })
        }
        let errorFn = function () {
            that.setState({
                loadingPackages: false,
            })
        }
        getAPI(interpolate(BED_PACKAGES, [this.state.currentClinic]), successFn, errorFn);

    }
    loadDiseases = () => {
        let that = this;
        let successFn = function (data) {
            that.setState({
                diseases: data
            })
        }
        let errorFn = function () {
        }
        getAPI(interpolate(DISEASE_LIST, [this.state.currentClinic]), successFn, errorFn);

    }
    loadClinics = () => {
        let that = this;
        this.setState({
            loadingClinics: true
        })
        let successFn = function (data) {
            that.setState({
                clinicsList: data,
                loadingClinics: false
            })
        }
        let errorFn = function () {
            that.setState({
                loadingClinics: false
            })
        }
        getAPI(ALL_PRACTICE, successFn, errorFn);

    }

    loadMedicinePackages() {
        let that = this;
        this.setState({
            loadingMedicinePackages: true
        })
        let successFn = function (data) {
            that.setState({
                medicinePackage: data,
                loadingMedicinePackages: false
            })
        }
        let errorFn = function () {
            that.setState({
                loadingMedicinePackages: false
            })
        }
        getAPI(interpolate(MEDICINE_PACKAGES, [this.state.currentClinic]), successFn, errorFn);
    }

    selectBedPackage = (bedPackage) => {
        this.setState({
            selectedBedPackage: bedPackage
        })
    }
    selectMedicinePackage = (medicnePackage) => {
        let that = this;
        this.setState(function (prevState) {
            if (prevState.selectedMedicinePackages[medicnePackage.id]) {
                let packages = {
                    ...prevState.selectedMedicinePackages,

                }
                delete packages[medicnePackage.id];
                return {
                    selectedMedicinePackages: packages
                }
            } else {
                return {
                    selectedMedicinePackages: {
                        ...prevState.selectedMedicinePackages,
                        [medicnePackage.id]: medicnePackage
                    }
                }
            }
        }, function () {
            that.calculateTotalAmount();
        })
    }

    calculateTotalAmount() {
        let that = this;
        that.setState(function (prevSate) {
            let payAmount = 0;
            let total_tax = 0;
            let bedPkg = {};
            let medicinePkg = [];
            let total_medicine_price = 0;
            let selectedBed = prevSate.selectedBedPackage;
            if (prevSate.seat_type == 'NORMAL') {
                payAmount = selectedBed.normal_price + selectedBed.normal_tax_value;
                total_tax += selectedBed.normal_tax_value
                bedPkg = {
                    ...selectedBed,
                    type: "BED",
                    price_with_tax: payAmount,
                    tax: selectedBed.normal_tax_value,
                    price: selectedBed.normal_price
                };
                medicinePkg = [bedPkg];
            }
            if (prevSate.seat_type == 'TATKAL') {
                payAmount = selectedBed.tatkal_price + selectedBed.tatkal_tax_value;
                total_tax += selectedBed.tatkal_tax_value
                bedPkg = {
                    ...selectedBed,
                    type: "BED",
                    price_with_tax: payAmount,
                    tax: selectedBed.tatkal_tax_value,
                    price: selectedBed.tatkal_price
                };
                medicinePkg = [bedPkg];
            }
            let keys = Object.keys(prevSate.selectedMedicinePackages);
            let medicinePackanges = keys.map(key => prevSate.selectedMedicinePackages[key]);
            medicinePackanges.forEach(function (item) {

                total_medicine_price += item.final_price;
                total_tax += item.tax_value;
                medicinePkg = [...medicinePkg, {
                    ...item,
                    type: "MEDICINE",
                    price_with_tax: total_medicine_price,
                    tax: item.tax_value
                }]
            });
            return {
                totalPayableAmount: payAmount + total_medicine_price,
                tax: total_tax,
                choosePkg: [...medicinePkg]
            }
        })
    }

    checkBedStatus = (type, value) => {
        let that = this;
        this.setState({
            [type]: value
        }, function () {

            let successFn = function (data) {
                that.setState({
                    availabilitySeatTatkal: data.TATKAL,
                    availabilitySeatNormal: data.NORMAL,
                });
                // let currentSelected = that.props.form.getFieldValue('seat_type');
                // if (!data[currentSelected]) {
                //     that.props.form.setFieldsValue({
                //         seat_type: null
                //     })
                //     that.calculateTotalAmount();
                // }
            }
            that.calculateTotalAmount();
            let errorFn = function () {

            }
            let {from_date, selectedBedPackage} = that.state;
            if (from_date && selectedBedPackage) {
                let to_date = null;
                to_date = moment(from_date).add(selectedBedPackage.no_of_days - 1, 'day')
                if (from_date && to_date && selectedBedPackage) {
                    getAPI(interpolate(CHECK_SEAT_AVAILABILITY, [this.state.currentClinic]), successFn, errorFn, {
                        start: moment(from_date).format('YYYY-MM-DD'),
                        end: moment(to_date).format('YYYY-MM-DD'),
                        bed_package: selectedBedPackage.id
                    })
                }
            }
        });
    }
    handleRoomType = (name, value) => {
        let that = this;
        this.setState({
            [name]: value,
        }, function () {
            that.calculateTotalAmount();
        });

    }
    saveDetails = (type, value) => {
        this.setState({
            [type]: value
        });
    }
    handleSubmit = (e) => {
        this.setState({
            loading: true
        });

        let that = this;
        let states = that.state;
        let keys = Object.keys(states.selectedMedicinePackages);
        let medicinePackanges = keys.map(key => states.selectedMedicinePackages[key].id);
        let reqData = {
            bed_package: that.state.selectedBedPackage ? that.state.selectedBedPackage.id : null,
            seat_type: that.state.seat_type,
            medicines: medicinePackanges,
            to_date: moment(states.from_date).add(that.state.selectedBedPackage.no_of_days - 1, 'day').format('YYYY-MM-DD'),
            from_date: moment(states.from_date).format('YYYY-MM-DD'),
            paid: false,
            total_price: that.state.totalPayableAmount.toFixed(2),
            date: moment().format('YYYY-MM-DD'),
            total_tax: that.state.tax,
            patient: that.props.currentPatient.id,
            creatinine: that.state.creatinine,
            urea_level: that.state.urea_level,
            dialysis: that.state.dialysis,
            other_diseases: that.state.other_diseases,
            rest_diseases: that.state.rest_diseases.join(','),
            report_upload: that.state.report_upload && that.state.report_upload.file && that.state.report_upload.file.response ? that.state.report_upload.file.response.image_path : null
        };
        let successFn = function (data) {
            displayMessage(SUCCESS_MSG_TYPE, "Saved Successfully!!");

            that.paymentHandler(data.id, "Booking", that.state.totalPayableAmount);
        }
        let errorFn = function () {

        }
        postAPI(interpolate(BOOK_SEAT, [that.state.currentClinic]), reqData, successFn, errorFn);
        // console.log(reqData)

    }

    paymentHandler(planId, planName, cost) {
        const that = this;
        const options = {
            key: RAZORPAY_KEY,
            amount: cost.toFixed(2) * 100,
            name: planName,

            description: 'Buy subscription...',

            handler(response) {
                const paymentId = response.razorpay_payment_id;
                let reqData = {
                    "payment_id": paymentId,
                    "reservation_id": planId
                };
                const successFn = function (data) {
                    displayMessage(SUCCESS_MSG_TYPE, data.detail);
                    that.props.history.goBack();
                    if (that.props.loadData)
                        that.props.loadData();
                };
                const errorFn = function () {
                    console.log('Request failed');
                };
                //Using my server endpoints to capture the payment
                postAPI(ACCEPT_PAYMENT, reqData, successFn, errorFn);
            },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    render() {
        let that = this;
        const singleUploadprops = {
            name: 'image',
            data: {
                name: 'hello'
            },
            action: makeURL(FILE_UPLOAD_API),
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {

                }
                if (info.file.status === 'done') {
                    console.log(info);
                    that.setState({
                        report_upload: info
                    })
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        const formItemLayout = (this.props.formLayout ? this.props.formLayout : {
            labelCol: {span: 6},
            wrapperCol: {span: 14},
        });
        const BOOKING_TYPE = [{
            value: 'NORMAL',
            is_or_not: this.state.availabilitySeatNormal && this.state.availabilitySeatNormal.available ? true : false,
            priceKey: 'final_normal_price'
        }, {
            value: 'TATKAL',
            is_or_not: this.state.availabilitySeatTatkal && this.state.availabilitySeatTatkal.available ? true : false,
            priceKey: 'final_tatkal_price'
        }];
        const columns = [{
            title: 'Item',
            key: 'name',
            dataIndex: 'name'
        },
            //     {
            //     title: 'Normal Price',
            //     key: 'normal_price',
            //     dataIndex: 'normal_price',
            //     // render:(value,record)=>(<p>{value.toFixed()}</p>)
            // }, {
            //     title: 'Tatkal Price',
            //     key: 'tatkal_price',
            //     dataIndex: 'tatkal_price',
            //     // render:(value,record)=>(<p>{record?(record.tatkal_price).toFixed():null}</p>)
            // },
            {
                title: 'Price',
                key: 'price',
                dataIndex: 'price',
                render: (value, record) => (<p>{value ? (value).toFixed(2) : null}</p>)

            }, {
                title: 'Tax',
                key: 'tax',
                dataIndex: 'tax',
                render: (value, record) => (<p>{value ? (value).toFixed(2) : null}</p>)
            }, {
                title: 'Total Amount',
                key: 'price_with_tax',
                dataIndex: 'price_with_tax',
                render: (value, record) => (<p>{value ? (value).toFixed(2) : null}</p>)
            }];
        let currentStep = 0;
        if (that.state.currentClinic) {
            currentStep += 2
        }
        if (that.state.selectedBedPackage) {
            currentStep += 1;
        }
        if (this.state.seat_type) {
            currentStep += 1;
        }
        if (this.state.seat_type || (!that.props.bedBooking && this.state.currentClinic)) {
            currentStep += 1;
        }
        if (this.state.patient_mobile) {
            currentStep += 1;
        }
        return <div style={{paddingBottom: 'calc(50vh)'}}>
            <Affix>
                <Card bodyStyle={{overflow:'scroll'}}>
                    <Steps progressDot current={currentStep}>
                        <Steps.Step title="Select Clinic"/>
                        <Steps.Step title="Fill Medical Details"/>
                        {that.props.bedBooking ? <Steps.Step title="Select Bed Package"/> : null}
                        {that.props.bedBooking ? <Steps.Step title="Check Availabilty"/> : null}
                        <Steps.Step title="Select Medicine Package"/>
                        {!that.props.bedBooking ? <Steps.Step title="Enter Delivery Details"/> : null}
                        <Steps.Step title="Pay"/>
                    </Steps>
                </Card>
            </Affix>
            <div id={"clinic"}>
                <h2>Select Clinic</h2>
                <Row>
                    {this.state.loadingClinics ?
                        <Spin><Row style={{height: 100}}/></Spin> :
                        this.state.clinicsList.map(list => <Col xs={24} sm={24}
                                                                md={12} lg={12}
                                                                xl={8}>
                            <Card hoverable onClick={() => that.setClinic(list.id)} key={list.id}
                                  bodyStyle={that.state.currentClinic == list.id ? {backgroundColor: '#0b9fcc'} : {}}>
                                <Card.Meta
                                    avatar={<Avatar src={makeFileURL(list.logo)} size={50}/>}
                                    title={<h2>{list.name}
                                    </h2>}
                                /></Card>
                        </Col>)}
                </Row>
                <Divider/>
            </div>
            {this.state.currentClinic ?
                <div id={"medicalldetails"}>
                    <h2>Your Medical Details</h2>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item key="creatinine" label="Creatinine Level"   {...formItemLayout}>
                                <Input placeholder="Creatinine Level"
                                       onChange={(e) => that.saveDetails('creatinine', e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item key="urea_level" label="Urea Level"   {...formItemLayout}>
                                <Input placeholder="Urea Level"
                                       onChange={(e) => that.saveDetails('urea_level', e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item key="dialysis" label="Currently on Dialysis"   {...formItemLayout}>
                                <Select onChange={(e) => that.saveDetails('dialysis', e)} style={{width: '100%'}}>
                                    <Select.Option value={true}>YES</Select.Option>
                                    <Select.Option value={false}>NO</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item key="other_diseases" label="Select your diseases"   {...formItemLayout}>
                                <Select onChange={(e) => that.saveDetails('other_diseases', e)} mode="multiple"
                                        style={{width: '100%'}}>
                                    {this.state.diseases.map(item => <Select.Option
                                        value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item key="rest_diseases" label="Any other diseases"   {...formItemLayout}>
                                <Select onChange={(e) => that.saveDetails('rest_diseases', e)} mode="tags"
                                        style={{width: '100%'}}>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Upload Report" {...formItemLayout}>
                                <Upload {...singleUploadprops}>
                                    <Button>
                                        <Icon type="upload"/> Select File
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Divider/>
                    </Row>
                </div> : null}
            {this.state.currentClinic && that.props.bedBooking ?
                <div id={"bedpackage"}>
                    <h2>Select Bed Packages</h2>
                    <Row gutter={16}>
                        {this.state.loadingPackages ?
                            <Spin><Row style={{height: 100}}/></Spin> :
                            this.state.packages.map(list => <Col xs={24} sm={24}
                                                                 md={12} lg={12}
                                                                 xl={8}>
                                <Card hoverable onClick={() => that.selectBedPackage(list)} key={list.id}
                                      cover={
                                          <img
                                              alt={list.name}
                                              src={makeFileURL(list.image)}
                                          />
                                      }
                                      bodyStyle={that.state.selectedBedPackage && that.state.selectedBedPackage.id == list.id ? {backgroundColor: '#0b9fcc'} : {}}>
                                    <Card.Meta
                                        title={list.name}
                                        description={<span>Normal Price : Rs. {list.final_normal_price}
                                            <br/>Tatkal Price : Rs. {list.final_tatkal_price}</span>}
                                    /></Card>
                            </Col>)}
                        <Divider/>
                    </Row>
                </div> : null}
            {this.state.selectedBedPackage && that.props.bedBooking ? <div>
                <h2>Select Date & Type</h2>
                <Row>
                    <Col span={24}>
                        <Form.Item key="patient_mobile" label="Date From"   {...formItemLayout}>
                            <DatePicker size="large"
                                        value={that.state.from_date}
                                        onChange={(value) => that.checkBedStatus('from_date', value)}
                                        format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item key="patient_mobile" label="Date To"   {...formItemLayout}>
                            <DatePicker disabled format={'DD-MM-YYYY'}
                                        size="large"
                                        value={(that.state.selectedBedPackage && that.state.from_date ? moment(that.state.from_date).add(that.state.selectedBedPackage.no_of_days - 1, 'day') : null)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{padding: 10}}>
                        <Form.Item key="patient_mobile" label="Select Seat Type"   {...formItemLayout}>
                            <Radio.Group buttonStyle="solid" size={"large"}
                                         onChange={(e) => this.handleRoomType('seat_type', e.target.value)}>
                                {BOOKING_TYPE.map((seat_type) => <Radio.Button
                                    value={seat_type.is_or_not ? seat_type.value : ''}
                                    disabled={seat_type.is_or_not ? false : true}>{seat_type.value} Seat
                                    (Rs. {that.state.selectedBedPackage[seat_type.priceKey]})</Radio.Button>)}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </div> : null}
            {this.state.seat_type || (!that.props.bedBooking && this.state.currentClinic) ? <div id={"bedpackage"}>
                <h2>Select Medicine Packages</h2>
                <Row gutter={16}>
                    {this.state.loadingMedicinePackages ?
                        <Spin><Row style={{height: 100}}/></Spin> :
                        this.state.medicinePackage.map(list => <Col xs={24} sm={24}
                                                                    md={12} lg={12}
                                                                    xl={8}>
                            <Card hoverable onClick={() => that.selectMedicinePackage(list)} key={list.id}
                                  cover={
                                      <img
                                          alt={list.name}
                                          src={makeFileURL(list.image)}
                                      />
                                  }
                                  bodyStyle={that.state.selectedMedicinePackages && that.state.selectedMedicinePackages[list.id] ? {backgroundColor: '#0b9fcc'} : {}}>
                                <Card.Meta
                                    title={list.name}
                                    description={<span>MRP : Rs. {list.final_price}</span>}
                                /></Card>
                        </Col>)}
                    <Divider/>
                </Row>
            </div> : null}

            {that.state.totalPayableAmount ? <Table pagination={false} columns={columns} size={'small'}
                                                    dataSource={this.state.choosePkg}/> : null}
            {that.state.totalPayableAmount && !that.props.bedBooking ? <div>
                <h2>Delivery Details</h2>
                <Row>

                    <Form.Item key="patient_mobile" label="Total Payable Amount"   {...formItemLayout}>

                        Rs. {that.state.totalPayableAmount}

                    </Form.Item>
                    <Form.Item key="patient_mobile" label="Name"   {...formItemLayout}>

                        <Input placeholder="Name" value={this.props.currentPatient.user.first_name} disabled
                               onChange={(e) => that.saveDetails('deliveryName', e.target.value)}/>

                    </Form.Item>
                    <Form.Item key="patient_mobile" label="Mobile Number"   {...formItemLayout}>

                        <Input placeholder="Mobile Number"/>
                    </Form.Item>
                    <Form.Item key="patient_mobile" label="Address"   {...formItemLayout}>

                        <Input.TextArea placeholder="Address"/>
                    </Form.Item>
                </Row>
            </div> : null}
            {that.state.totalPayableAmount ? <div>

                <Col span={24} style={{textAlign: 'center'}}>
                    <h3>Grand
                        Total: <b>{this.state.totalPayableAmount ? this.state.totalPayableAmount.toFixed(2) : null}</b>
                    </h3>
                    <Button onClick={() => that.handleSubmit()} type={"primary"} size={"large"}>
                        Pay Now
                    </Button>
                </Col>
            </div> : null}
        </div>
    }
}
