import React from "react";
import {Avatar, Input, Table, Col, Button, Card, Icon, Tabs, Divider} from "antd";
import {Link} from "react-router-dom";
import {VITAL_SIGNS_API, VITAL_SIGN_PDF} from "../../../constants/api";
import {getAPI, interpolate} from "../../../utils/common";
import moment from 'moment';
import {
    AreaChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Area,
    LineChart,
    Line,
    ResponsiveContainer, ComposedChart
} from 'recharts';
import CustomizedTable from "../../common/CustomizedTable";
import {BACKEND_BASE_URL} from "../../../config/connect";

const {Meta} = Card;
const Search = Input.Search;

class PatientVitalSign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: this.props.currentPatient,
            vitalsign: [],
            loading: true
        }
        this.loadVitalsigns = this.loadVitalsigns.bind(this);

    }

    componentDidMount() {
        this.loadVitalsigns();
    }

    loadVitalsigns() {
        let that = this;
        let successFn = function (data) {
            that.setState(function (prevState) {
                return {
                    vitalsign: [...prevState.vitalsign, ...data.results],
                    next: data.next,
                    loading: false
                }
            })
        }
        let errorFn = function () {

        }
        getAPI(interpolate(VITAL_SIGNS_API, [this.props.currentPatient.id]), successFn, errorFn)
    }

    loadPDF(id) {
        let that = this;
        let successFn = function (data) {
            if (data.report)
                window.open(BACKEND_BASE_URL + data.report);
        }
        let errorFn = function () {

        }
        getAPI(interpolate(VITAL_SIGN_PDF, [id]), successFn, errorFn);
    }

    render() {
        let that = this;

        const columns = [{
            title: 'Time',
            dataIndex: 'created_at',
            key: 'name',
            render: created_at => <span>{moment(created_at).format('LLL')}</span>,
        }, {
            title: 'Temp(F)',
            key: 'temperature',
            render: (text, record) => (
                <span> {record.temperature}<br/>,{record.temperature_part}</span>
            )
        }, {
            title: 'Pulse (BPM)',
            dataIndex: 'pulse',
            key: 'pulse',
        }, {
            title: 'RR breaths/min',
            dataIndex: 'resp_rate',
            key: 'resp_rate',
        }, {
            title: 'SYS/DIA mmhg',
            key: 'address',
            render: (text, record) => (<>{record.blood_pressure_up ?
                    <span> {record.blood_pressure_up}/{record.blood_pressure_down}<br/>,{record.position}</span> : null}
                </>
            )
        }, {
            title: 'WEIGHT kg',
            dataIndex: 'weight',
            key: 'weight',
        }, {
            title: "Creatinine Level",
            key: "creatinine",
            dataIndex: "creatinine",
        }, {
            title: "Haemoglobin Level",
            key: "haemoglobin",
            dataIndex: "haemoglobin",
        }, {
            title: "Urea Level",
            key: "urea",
            dataIndex: "urea",

        }, {
            title: "Uric Acid Level",
            key: "uric_acid",
            dataIndex: "uric_acid",
        }, {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <a onClick={() => this.loadPDF(record.id)}
                    >Print</a>
                    <Divider type="vertical"/>
                  <a onClick={() => this.editObject(record)}
                     disabled={(record.practice != that.props.active_practiceId)}>Edit</a>
                    <Divider type="vertical"/>
                    <a onClick={() => that.deleteVitalSign(record)}
                       disabled={(record.practice != that.props.active_practiceId)}>Delete</a>
                </span>
            ),
        }];

        return <Card bodyStyle={{padding: 0}}
                     title={this.state.currentPatient ? this.state.currentPatient.user.first_name + " Manual Report" : "Patient Manual Report"}>
            <Tabs>
                <Tabs.TabPane tab={"Charts"} key={1} style={{margin: 'auto', width: '100%'}}>
                    <Divider>Pulse Chart (bpm)</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                {/*<defs>*/}
                                {/*    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">*/}
                                {/*        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>*/}
                                {/*        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>*/}
                                {/*    </linearGradient>*/}
                                {/*    <Legend verticalAlign="top" height={36}/>*/}
                                {/*</defs>*/}
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={that.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="pulse" stroke="#8884d8" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Temperature (F)</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <Legend verticalAlign="top" height={36}/>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>

                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="temperature" stroke="#82ca9d" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Blood Pressure (mmhg)</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="blood_pressure_up" stroke="#ffc658" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                                <Line type="monotone" dataKey="blood_pressure_down" stroke="#82ca9d" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Weight (kg)</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="weight" stroke="#8884d8" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Respiratory Rate (breaths/min)</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="resp_rate" stroke="#82ca9d" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Creatinine Level</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="creatinine" stroke="#82ca9d" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Haemoglobin Level</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="haemoglobin" stroke="#82ca9d" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Urea Level</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="urea" stroke="#82ca9d" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <Divider>Uric Acid Level</Divider>
                    <div style={{height: 200}}>
                        <ResponsiveContainer>
                            <ComposedChart width={700} height={200} data={this.state.vitalsign.reverse()}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="created_at" tickFormatter={(value) => {
                                    return moment(value).format('LLL')
                                }} tickCount={this.state.vitalsign.length}/>
                                <YAxis
                                    domain={[dataMin => (Math.abs(dataMin)), dataMax => (dataMax * 1.1).toFixed(0)]}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip
                                    labelFormatter={(value) => (value && moment(value).isValid() ? moment(value).format('LLL') : '--')}/>
                                <Line type="monotone" dataKey="uric_acid" stroke="#82ca9d" fillOpacity={1}
                                      strokeWidth={4}
                                      fill="url(#colorUv)"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Details"} key={2}>
                    <CustomizedTable loading={this.state.loading} columns={columns}
                                     pagination={false}
                                     dataSource={this.state.vitalsign}/>
                    {/* <InfiniteFeedLoaderButton loaderFunction={() => this.loadInvoices(that.state.next)}
                                          loading={this.state.loading}
                                          hidden={!this.state.next}/> */}

                </Tabs.TabPane>

            </Tabs>
        </Card>
    }

}

export default PatientVitalSign;
