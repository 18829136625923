import React, {Component} from 'react';
import {Layout} from "antd";
import {Route, Switch} from "react-router-dom";
import {
    loadUserDetails,
    loggedInUser,
    logInUser,
    logOutUser,
} from "./app/utils/auth";
import AppBase from "./app/components/core/AppBase";
import Auth from "./app/components/auth/Auth";
import ReactGA from 'react-ga';

class App extends Component {
    constructor(props) {
        super(props);
        ReactGA.initialize('UA-143616458-2');
        this.state = {
            user: loadUserDetails(),
            redirect: false,
            active_practiceId:10
        };
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    login(data) {
        let user = loadUserDetails();
        this.setState({
            user: user,
        });
    }

    logout() {
        let that = this;
        let successFn = function () {
            that.setState({
                user: null
            });
        };
        let errorFn = function () {
        };
        logOutUser(successFn, errorFn);
    }

    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        return <Layout>
            <Switch>
                <Route exact path="/login" render={() => <Auth {...this.state} login={this.login}/>}/>
                <Route exact path="/password-reset/:token"
                       render={(route) => <Auth {...route} {...this.state} login={this.login}/>}/>
                <Route render={() => (this.state.user ?
                    <AppBase {...this.state} logout={this.logout}/> :
                    <Auth {...this.state} login={this.login}/>)}/>
            </Switch>
        </Layout>
    }
}

export default App;
