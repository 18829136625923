import React from "react";

import {Layout} from "antd";
import {Redirect, Route} from "react-router";
import PatientProfile from "./patient/PatientProfile";
import EditPatientDetails from "./patient/EditPatientDetails";
import Appointment from "./appointment/Appointment"
import PatientSider from "./PatientSider";
import PatientTimeline from "./timeline/PatientTimeline";
import PatientFiles from "./files/PatientFiles";
import PatientClinicNotes from "./clinicNotes/PatientClinicNotes";
import PatientCommunication from "./communication/PatientCommunication";
import PatientVitalSign from "./vitalSign/PatientVitalSign";
import AddorEditPatientVitalSigns from "./vitalSign/AddorEditPatientVitalSigns";
import PatientCompletedProcedures from "./completedProcedures/PatientCompletedProcedures";
import PatientPrescriptions from "./prescriptions/PatientPrescriptions";
import PatientTreatmentPlans from "./treatmentPlans/PatientTreatmentPlans";
import PatientLabOrders from "./labOrders/PatientLabOrders";
import PatientInvoices from "./invoices/PatientInvoices";
import PatientPayments from "./payments/PatientPayments";
import PatientLedgers from "./ledgers/PatientLedgers";
import {Switch} from "react-router-dom";
import PrescriptionTemplate from "./prescriptions/PrescriptionTemplate";
import Profile from "../auth/Profile";
import BookingHome from "../booking/BookingHome";
import Error404 from "../common/errors/Error404";
import InvoicesReturn from "./invoices/returnInvoices/InvoicesReturn";

const {Content} = Layout;

class PatientHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: this.props.user,
            active_practiceId: this.props.active_practiceId,
            medicalHistory: [],
            listModalVisible: false
        };
        this.setCurrentPatient = this.setCurrentPatient.bind(this);
        this.togglePatientListModal = this.togglePatientListModal.bind(this);
    }


    setCurrentPatient(patientObj) {
        this.setState({
            currentPatient: patientObj,
            listModalVisible: false
        });
    }

    togglePatientListModal(option) {
        this.setState({
            listModalVisible: !!option
        });
    }

    render() {
        return <Content>
            {/*<PatientHeader {...this.state} togglePatientListModal={this.togglePatientListModal}*/}
            {/*setCurrentPatient={this.setCurrentPatient}/>*/}
            <Layout>
                <PatientSider {...this.state}/>
                <Layout>
                    <Content className="main-container"
                             style={{
                                 // margin: '24px 16px',
                                 padding: 10,
                                 minHeight: 280,
                                 // marginLeft: '200px'
                             }}>
                        <Switch>
                            <Route exact path="/profile"
                                   render={() => <Profile {...this.state}
                                                          key={this.state.currentPatient} {...this.props}/>}/>

                            {/*** Patient Profile Routes*/}
                            <Route exact path='/'
                                   render={() => <PatientProfile {...this.state}
                                                                 key={this.state.currentPatient}
                                                                 setCurrentPatient={this.setCurrentPatient} {...this.props}/>}/>
                            <Route exact path='/patient/:id/profile/edit'
                                   render={() => <EditPatientDetails
                                       key={this.state.currentPatient}{...this.state} {...this.props}
                                       setCurrentPatient={this.setCurrentPatient}/>}/>

                            {/*** Patient Appointment Routes*/}
                            <Route exact path='/patient/:id/appointments'
                                   render={(route) =>
                                       <Appointment key={this.state.currentPatient} {...this.state} {...route}/>}/>
                            {/*<Route exact path='/patient/:id/appointments'*/}
                            {/*render={(route) => <Appointment*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}
                            <Route exact path='/appointments/:appointmentid'
                                   render={(route) => <Appointment
                                       key={this.state.currentPatient} {...this.state} {...route}/>}/>
                            {/*      <Route exact path='/patients/appointments/create'
                           render={() => <CreateAppointment {...this.props} />}/>*/}

                            {/*** Patient Communication Routes*/}
                            {/*<Route path='/communications'*/}
                            {/*render={() => <PatientCommunication/>}/>*/}
                            {/*<Route exact path='/patient/:id/communications'*/}
                            {/*render={(route) => <PatientCommunication*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}

                            {/*** Patient Vital Sign Routes*/}
                            <Route exact path='/emr/vitalsigns'
                                   render={(route) =>
                                       <PatientVitalSign
                                           key={this.state.currentPatient} {...this.state} {...route}/>}/>
                            {/*<Route exact path='/patient/:id/emr/vitalsigns'*/}
                            {/*render={(route) => <PatientVitalSign*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route} />}/>*/}
                            {/*<Route exact path='/patient/:id/emr/vitalsigns/add'*/}
                            {/*render={(route) => <AddorEditPatientVitalSigns*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}
                            {/*<Route exact path='/patient/:id/emr/vitalsigns/edit'*/}
                            {/*render={(route) => <AddorEditPatientVitalSigns*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}

                            {/*** Patient Clinic Notes Routes*/}
                            <Route path={"/emr/clinicnotes"}
                                   render={(route) => <PatientClinicNotes
                                       key={this.state.currentPatient} {...this.props} {...this.state} {...route}/>}/>
                            {/*<Route path={"/patient/:id/emr/clinicnotes"}*/}
                            {/*render={(route) =>*/}
                            {/*<PatientClinicNotes*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}

                            {/*** Patient Completed Procedures Routes*/}
                            <Route exact path='/emr/workdone'
                                   render={(route) =>
                                       <PatientCompletedProcedures
                                           key={this.state.currentPatient} {...this.state} {...route} />}/>
                            {/*<Route exact path='/patient/:id/emr/workdone'*/}
                            {/*render={(route) => <PatientCompletedProcedures*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}

                            {/*** Patient Files Routes*/}
                            <Route exact path='/emr/files'
                                   render={(route) =>
                                       <PatientFiles key={this.state.currentPatient} {...route} {...this.state}/>}/>
                            {/*<Route path={"/patient/:id/emr/files"}*/}
                            {/*render={(route) => <PatientFiles*/}
                            {/*key={this.state.currentPatient} {...route} {...this.state}/>}/>*/}

                            {/*** Patient Prescriptions Routes*/}
                            <Route exact path='/emr/prescriptions'
                                   render={(route) =>
                                       <PatientPrescriptions
                                           key={this.state.currentPatient} {...this.state} {...route}/>}/>
                            {/*<Route path='/patient/:id/emr/prescriptions'*/}
                            {/*render={(route) => <PatientPrescriptions*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route} />}/>*/}

                            {/*** Patient Treatment Plan Routes*/}
                            <Route exact path='/emr/plans'
                                   render={(route) =>
                                       <PatientTreatmentPlans
                                           key={this.state.currentPatient} {...this.state} {...route}/>}/>
                            {/*<Route path='/patient/:id/emr/plans'*/}
                            {/*render={(route) => <PatientTreatmentPlans*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route} />}/>*/}

                            {/*** Patient Timeline Routes*/}
                            <Route exact path="/emr/timeline"
                                   render={(route) =>
                                       <PatientTimeline
                                           key={this.state.currentPatient} {...this.state} {...route}/>}/>

                            {/*** Patient Lab Order Routes*/}
                            <Route exact path='/emr/labtrackings'
                                   render={(route) =>
                                       <PatientLabOrders
                                           key={this.state.currentPatient} {...this.state} {...route}/>}/>

                            {/*<Route path='/patient/:id/emr/labtrackings'*/}
                            {/*render={(route) => <PatientLabOrders*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route} />}/>*/}

                            {/*** Patient Invoices Routes*/}
                            <Route exact path='/billing/invoices'
                                   render={(route) =>
                                       <PatientInvoices
                                           key={this.state.currentPatient} {...this.props} {...this.state} {...route}/>}/>

                            <Route exact path='/billing/return/invoices'
                                   render={(route) =>
                                       <InvoicesReturn
                                           key={this.state.currentPatient} {...this.props} {...this.state} {...route}/>}/>

                            {/*<Route path='/patient/:id/billing/invoices'*/}
                            {/*render={(route) => <PatientInvoices*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}

                            {/*** Patient Payments Routes*/}
                            <Route path='/billing/payments'
                                   render={(route) =>
                                       <PatientPayments key={this.state.currentPatient} {...this.state} {...route}/>}/>
                            {/*<Route path='/patient/:id/billing/payments'*/}
                            {/*render={(route) => <PatientPayments*/}
                            {/*key={this.state.currentPatient} {...this.state} {...route}/>}/>*/}

                            {/*** Patient Ledger Routes*/}
                            <Route exact path='/billing/ledger'
                                render={() => <PatientLedgers key={this.state.currentPatient} {...this.state}/>}/>

                            {/*<Route exact path='/patient/:id/prescriptions/template/add'*/}
                            {/*render={(route) => <PrescriptionTemplate {...this.state} {...route}/>}/>*/}
                            <Route path="/booking" render={(route) => <BookingHome
                                key={this.state.currentPatient} {...this.state} {...route}/>}/>

                            <Route component={Error404}/>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </Content>
    }
}

export default PatientHome;
