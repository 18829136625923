import React from "react";
import {Card, Table, Row, Button, Icon, Checkbox, Col} from "antd";
import {Link} from "react-router-dom";
import {BACKEND_BASE_URL} from "../../../config/connect";
import {getAPI, interpolate} from "../../../utils/common";
import {PATIENT_LEDGER} from "../../../constants/api";
import {
    patientInvoiceDetailsInString,
    patientPaymentDetailsInString,
    patientReturnInvoiceDetailsInString
} from "../../../utils/patientUtils";

class PatientLedgers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ledger: [],
            loadingLedger: false,
            selectedList: {},
            credit: 0,
            debit: 0,
            balance: 0,
        }
    }

    componentDidMount() {
        this.loadPatientLedger();
    }

    loadPatientLedger = () => {
        let that = this;
        this.setState({
            loadingLedger: true
        })
        let successFn = function (data) {
            let ledgerData = [];
            let balance = 0;
            let totalCredit = 0;
            let totalDebit = 0;
            data.forEach(function (dataRow) {
                let ledgerEntry = {
                    date: dataRow.date,
                    type: dataRow.ledger_type
                };
                if (dataRow.ledger_type == 'Invoice') {
                    ledgerEntry.type_id = dataRow.invoice_id;
                    ledgerEntry.details = patientInvoiceDetailsInString(dataRow);
                    ledgerEntry.credit = dataRow.total;
                    ledgerEntry.debit = 0;

                } else if (dataRow.ledger_type == 'Payment') {
                    ledgerEntry.type_id = dataRow.payment_id;
                    ledgerEntry.details = patientPaymentDetailsInString(dataRow);
                    ledgerEntry.credit = 0;
                    ledgerEntry.debit = dataRow.total;
                } else if (dataRow.ledger_type == 'Return') {
                    ledgerEntry.type_id = dataRow.return_id;
                    ledgerEntry.details = patientReturnInvoiceDetailsInString(dataRow);
                    ledgerEntry.credit = dataRow.cash_return;
                    ledgerEntry.debit = dataRow.total;
                }
                balance += ledgerEntry.credit;
                balance -= ledgerEntry.debit;
                totalDebit += ledgerEntry.debit;
                totalCredit += ledgerEntry.credit;
                ledgerEntry.balance = balance;
                ledgerData.push(ledgerEntry);
            });
            that.setState({
                loadingLedger: false,
                credit: totalCredit,
                debit: totalDebit,
                balance: balance,
                ledger: ledgerData
            })
        }
        let errorFn = function () {
            that.setState({
                loadingLedger: false
            })
        }

        getAPI(interpolate(PATIENT_LEDGER, [that.props.currentPatient.id]), successFn, errorFn);
    }

    ledgerCompleteToggle(id, option) {
        this.setState(function (prevState) {
            return {selectedList: {...prevState.selectedList, [id]: !!option}}
        });
    }

    loadPDF = (id) => {
        let that = this;
        let successFn = function (data) {
            if (data.report)
                window.open(BACKEND_BASE_URL + data.report);
        }
        let errorFn = function () {

        }
        // getAPI(interpolate(INVOICE_PDF_API, [id]), successFn, errorFn);
    }

    render() {
        const columns = [
            //     {
            //     title: '',
            //     key: 'is_completed',
            //     render: (text, record) => (record.is_completed ?
            //         <Icon type="check-circle" theme="twoTone" style={{marginLeft: '8px', fontSize: '20px'}}/> :
            //         <Checkbox key={record.id}
            //                   onChange={(e) => this.ledgerCompleteToggle(record.id, e.target.checked)}
            //                   value={this.state.selectedList[record.id]}/>)
            // },
            {
                title: 'Date',
                key: 'date',
                dataIndex: "date"
            }, {
                title: 'Invoice/ Receipt/ Return No',
                key: 'type_id',
                dataIndex: "type_id",
            }, {
                title: 'Details',
                key: 'details',
                dataIndex: "details",
            }, {
                title: 'Type',
                key: 'type',
                dataIndex: "type",
            }, {
                title: 'Credit(INR)',
                key: 'credit',
                dataIndex: "credit",
                align: "right",
                render: value => value.toFixed(2)
            }, {
                title: 'Debit(INR)',
                key: 'debit',
                dataIndex: "debit",
                align: "right",
                render: value => value.toFixed(2)
            }, {
                title: 'Balance(INR)',
                key: 'balance',
                dataIndex: "balance",
                align: "right",
                render: value => value.toFixed(2)
            }];
        return <Row>
            <Card
                title={this.state.currentPatient ? this.state.currentPatient.name + " Payment Ledger" : "Patient Ledgers"}>
                <Table loading={this.state.loadingLedger} columns={columns}
                       dataSource={this.state.ledger} pagination={false}/>
                <Row style={{marginTop: 20, textAlign: 'center'}}>
                    <Col span={8}>
                        <h3>Total Credit: {this.state.credit.toFixed(2)}</h3>
                    </Col>
                    <Col span={8}>
                        <h3>Total Debit: {this.state.debit.toFixed(2)}</h3>
                    </Col>
                    <Col span={8}>
                        <h3>Total Balance: {this.state.balance.toFixed(2)}</h3>
                    </Col>
                </Row>
            </Card>
        </Row>
    }
}

export default PatientLedgers;
