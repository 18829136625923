import React from "react";
import {Route} from "react-router";
import {} from "react-router-dom";

import DynamicFieldsForm from "../../common/DynamicFieldsForm";
import {Button, Card, Checkbox, Divider, Form, Icon, Row, Table, Tag, Popconfirm} from "antd";
import {
    CHECKBOX_FIELD,
    DATE_PICKER,
    NUMBER_FIELD,
    SUCCESS_MSG_TYPE,
    INPUT_FIELD,
    RADIO_FIELD,
    SELECT_FIELD,
    DOCTORS_ROLE
} from "../../../constants/dataKeys";
import {
    PATIENTS_LIST,
    PATIENT_PROFILE,
    APPOINTMENT_PERPRACTICE_API,
    PATIENT_APPOINTMENT_REPORTS,
    PROCEDURE_CATEGORY,
    PRACTICESTAFF, EMR_TREATMENTNOTES, APPOINTMENT_API, APPOINTMENT_REPORTS, SINGLE_APPOINTMENT_PERPRACTICE_API
} from "../../../constants/api";
import {getAPI, interpolate, displayMessage, putAPI, postAPI} from "../../../utils/common";
import {Redirect, Link} from 'react-router-dom'
import moment from 'moment';
import CustomizedTable from "../../common/CustomizedTable";


class Appointment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            appointments: null,
            loading: false
        }
        this.changeRedirect = this.changeRedirect.bind(this);
        this.loadAppointment = this.loadAppointment.bind(this);

    }

    componentDidMount() {
        this.loadAppointment();
    }

    loadAppointment() {
        let that = this;
        this.setState({
            loading: true,
        })
        let successFn = function (data) {
            that.setState({
                appointments: data,
                loading: false,
            });
        }
        let errorFn = function () {
            that.setState({
                loading: false,

            })
        }
        getAPI(interpolate(APPOINTMENT_API, [this.props.currentPatient.id]), successFn, errorFn);
    }

    changeRedirect() {
        var redirectVar = this.state.redirect;
        this.setState({
            redirect: !redirectVar,
        });
    }

    render() {
        let that = this;
        const procedures = {}
        if (this.state.procedure_category) {
            this.state.procedure_category.forEach(function (procedure) {
                procedures[procedure.id] = procedure.name;
            })
        }

        const treatmentNotes = [];
        if (this.state.treatmentNotes) {
            this.state.treatmentNotes.forEach(function (treatmentNote) {
                treatmentNotes[treatmentNote.id] = treatmentNote.name
            })
        }
        const categories = {1: "fast", 2: "Full Stomach", 3: "No Liquids"}
        const columns = [{
            title: 'Schedule Time',
            key: 'name',
            render: (text, record) => (<span>{moment(record.schedule_at).format('LLL')},{record.slot}mins</span>
            ),
        },
            //     {
            //     title: 'Patient ID',
            //     dataIndex: 'patient.id',
            //     key: 'patient_id',
            // },
            //     {
            //     title: 'Patient Name',
            //     dataIndex: 'patient.user.first_name',
            //     key: 'patient_name',
            // }, {
            //     title: 'Patient Mobile',
            //     dataIndex: 'patient.user.mobile',
            //     key: 'patient_mobile',
            // }, {
            //     title: 'Email',
            //     dataIndex: 'patient.user.email',
            //     key: 'email',
            // },
            {
                title: 'Doctor',
                key: 'doctor',
                dataIndex: 'doctor_data.user.first_name'
            }, {
                title: 'Category',
                key: 'category.name',
                dataIndex: 'category_data.name'
            },];
        // const TestFormLayout = Form.create()(DynamicFieldsForm);
        return <Card bodyStyle={{padding: 0}}
                     title={this.state.currentPatient ? this.state.currentPatient.user.first_name + " Patient Appointment" : "Patient Appointment"}
                     extra={<Link to={"/calendar/create-appointment"}><Button>Create</Button></Link>}>
            <CustomizedTable loading={this.state.loading} columns={columns}
                             hideReport={true}
                             pagination={false}
                             dataSource={this.state.appointments}/>

        </Card>
    }

}

export default Appointment;
