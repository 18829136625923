import React from "react";
import {
    Affix,
    Alert,
    Card,
    Col,
    Divider,
    Modal,
    Row,
    Spin,
    Statistic,
    Table,
    Tag,
    Tooltip,
    Form,
    Menu,
    Icon,
    Button,
    Dropdown,
    Input,
} from "antd";
import {displayMessage, getAPI, interpolate, putAPI, postAPI} from "../../../../utils/common";
import {
    INVOICE_RETURN_API,
} from "../../../../constants/api";
import moment from "moment";
import {Route, Switch} from "react-router";
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton";
import {Link} from "react-router-dom";

class InvoicesReturn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: this.props.currentPatient,
            active_practiceId: this.props.active_practiceId,
            returnInvoices: [],
            loading: true,
        }
        this.loadReturnInvoices = this.loadReturnInvoices.bind(this);

    }

    componentDidMount() {
        this.loadReturnInvoices();
    }

    loadReturnInvoices() {
        let that = this;
        that.setState({
            loading: true
        });
        let successFn = function (data) {
           that.setState({
               returnInvoices:data.results,
               loading:false,
           })
        }
        let errorFn = function () {
            that.setState({
                loading: false
            })

        }
        let apiParams = {
            page: 1,
            page_size:1,
        };
        if (this.props.match.params.id) {
            apiParams.patient = this.props.match.params.id;
        }
        getAPI(INVOICE_RETURN_API, successFn, errorFn, apiParams);
    }


    render() {
        let that = this;
        return <div>
            <Switch>
                <Route>
                    <div>
                        <Affix offsetTop={0}>
                            <Card
                                bodyStyle={{padding: 0}}
                                title={"Latest Return Invoices"}>
                            </Card>

                        </Affix>

                        {this.state.returnInvoices.map(invoice => InvoiceCard(invoice, that))}
                        <Spin spinning={this.state.loading}>
                            <Row/>
                        </Spin>
                        <InfiniteFeedLoaderButton
                            loaderFunction={() => this.loadInvoices(this.state.loadMoreInvoice)}
                            loading={this.state.loading}
                            hidden={!this.state.loadMoreInvoice}/>

                    </div>
                </Route>
            </Switch>
        </div>


    }
}

export default Form.create()(InvoicesReturn);

function invoiceFooter(presc) {
    if (presc) {
        return <p>
            {presc.staff ? <Tooltip title="Staff"><Tag color={presc.staff ? presc.staff_data.calendar_colour : null}>
                <b>{"Return by  " + presc.staff_data.user.first_name} </b>
            </Tag></Tooltip> : null}
            {presc.practice ? <Tag style={{float: 'right'}}>
                <Tooltip title="Practice Name">
                    <b>{presc.practice_data.name} </b>
                </Tooltip>
            </Tag> : null}
        </p>
    }
    return null
}

function InvoiceCard(invoice, that) {
    const {getFieldDecorator} = that.props.form;

    return <Card
        key={invoice.id}
        style={{marginTop: 10}}
        bodyStyle={{padding: 0}}
        title={<small>{invoice.date ? moment(invoice.date).format('ll') : null}
            {that.state.currentPatient ? null : <span>
            <Link to={"/patient/" + (invoice.patient_data ? invoice.patient_data.id : null) + "/billing/return/invoices"}>
                &nbsp;&nbsp; {invoice.patient_data ? invoice.patient_data.user.first_name : null} (ID: {invoice.patient_data ? invoice.patient_data.id : null})&nbsp;
            </Link>, {invoice.patient_data ? invoice.patient_data.gender : null}</span>}
        </small>}>
        <Row gutter={8}>
            <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4} style={{padding: 10}}>
                {invoice.is_cancelled ?
                    <Alert message="Cancelled" type="error" showIcon/> : null}
                <Divider style={{marginBottom: 0}}>{invoice.return_id}</Divider>
                <Statistic title="Cash / Return "
                           value={(invoice.cash_return ? invoice.cash_return.toFixed(2) : 0)}
                           suffix={"/ " + (invoice.return_value ? invoice.return_value.toFixed(2):0)}/>
            </Col>
            <Col xs={24} sm={24} md={18} lg={20} xl={20} xxl={20}>

                <Table
                    bordered={true}
                    pagination={false}
                    columns={columns}
                    dataSource={[...invoice.inventory, ...invoice.procedure]}
                    footer={() => invoiceFooter({...invoice})}/>
            </Col>
        </Row>

        <Modal
            visible={(that.state.cancelReturnIncoiceVisible && that.state.editReturnInvoice && that.state.editReturnInvoice.id == invoice.id)}
            title="Cancel Return Invoice"
            footer={null}
            onOk={that.handleSubmitCancelReturnInvoice}
            onCancel={that.cancelReturnInvoiceClose}>
            <Form>
                <Form.Item>
                    {getFieldDecorator('otp', {
                        rules: [{required: true, message: 'Please input Otp!'}],
                    })(
                        <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                               placeholder="Otp"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {that.state.otpSent ? <a style={{float: 'right'}} type="primary" onClick={that.sendOTP}>
                        Resend Otp ?
                    </a> : null}
                    <Button size="small" type="primary" htmlType="submit" onClick={that.handleSubmitCancelReturnInvoice}>
                        Submit
                    </Button>&nbsp;
                    <Button size="small" onClick={that.cancelReturnInvoiceClose}>
                        Close
                    </Button>
                </Form.Item>
            </Form>
        </Modal>



    </Card>
}

const columns = [{
    title: 'Treatment & Products',
    dataIndex: 'drug',
    key: 'drug',
    render: (text, record) => (
        <span> <b>{record.name ? record.name : null}</b>
            {/* <br/> {record.staff_data ?
                <Tag color={record.staff_data ? record.staff_data.calendar_colour : null}>
                    <b>{"return by  " + record.staff_data.user.first_name} </b>
                </Tag> : null} */}
                </span>)
}, {
    title: 'Cost',
    dataIndex: 'unit_cost',
    key: 'unit_cost',
    render: (item, record) => <span>{record.unit_cost ? record.unit_cost.toFixed(2) : null}</span>
}, {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
}, {
    title: 'Discount',
    dataIndex: 'discount_value',
    key: 'discount_value',
    render: (item, record) => <span>{record.discount_value ? record.discount_value.toFixed(2) : null}</span>
}, {
    title: 'Tax',
    dataIndex: 'tax_value',
    key: 'tax_value',
    render: (item, record) => <span>{record.tax_value ? record.tax_value.toFixed(2) : null}</span>
}, {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: item => item ? item.toFixed(2) : null
}];
