import React from "react";
import {Divider } from 'antd';
class AppFooter extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
          <Divider/>
          <p>made by :PLUTONIC DEVS</p>
          </div>
    }
}
export default AppFooter;
