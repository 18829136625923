import React from "react";
import {Card, Button, Icon, Table, Divider, Checkbox} from "antd";
import moment from "moment";

class PatientLabOrders extends React.Component{
	constructor(props){
        super(props);
        this.state={
            currentPatient: this.props.currentPatient,
            active_practiceId: this.props.active_practiceId,
        	loading:false,
        }
    }

    render(){
    	const columns = [{
            title: 'Time',
            dataIndex: 'created_at',
            key: 'name',
            render: created_at => <span>{moment(created_at).format('LLL')}</span>,
        }, {
            title: 'Drug',
            key: 'drug',
            
        }, {
            title: 'Quantity',
            dataIndex: 'qunatity',
            key: 'quantity',
        }, {
            title: 'Cost Per  Unit',
            dataIndex: 'cost',
            key: 'cost',
        }, {
            title: 'Completed',
            key: 'is_completed',
            render: (text, record) => (
                <Checkbox checked={record.is_completed}/>
            )
        }];

    	return <div><Card title={ this.state.currentPatient?this.state.currentPatient.user.first_name + " Lab Order":"Patients Lab Order"}>
                    <Table loading={this.state.loading} columns={columns}  dataSource={this.state.payments} />

              </Card>
        </div>
    }

}

export default PatientLabOrders;