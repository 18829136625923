import React from "react";
import {
    Form,
    Input,
    Select,
    CheckBox,
    DatePicker,
    Button,
    InputNumber,
    Card,
    List,
    Avatar,
    AutoComplete,
    Alert,
    Icon
} from 'antd';
import {REQUIRED_FIELD_MESSAGE} from "../../constants/messages";
import moment from "moment/moment";
import {DOCTORS_ROLE, SUCCESS_MSG_TYPE} from "../../constants/dataKeys";
import {
    APPOINTMENT_API,
    APPOINTMENT_CATEGORIES,
    EMR_TREATMENTNOTES,
    PRACTICESTAFF, PROCEDURE_CATEGORY,
    SEARCH_PATIENT,
    ALL_APPOINTMENT_API,
    PATIENT_PROFILE,
    APPOINTMENT_PERPRACTICE_API, ALL_PRACTICE,
    APPOINTMENT_SCHEDULE,
    BLOCK_CALENDAR,
    CALENDER_SETTINGS, DOCTOR_VISIT_TIMING_API

} from "../../constants/api";
import {Checkbox, Radio} from "antd/lib/index";
import {DAY_KEYS} from "../../constants/hardData";
import {displayMessage, getAPI, interpolate, postAPI, putAPI} from "../../utils/common";
import {Redirect} from "react-router-dom";

const {TextArea} = Input;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const {Meta} = Card;
export default class CreateAppointmentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            practice_doctors: [],
            appointmentCategories: null,
            procedure_category: null,
            treatmentNotes: null,
            practice_staff: [],
            clinics: [],
            appointment: null,
            loading: false,
            patientListData: [],
            patientDetails: null,
            appointmentDetail: null,
            currentClinic: null,
            doctorBlock: false,
            doctorOutsideAvailableTiming: false,
            practiceBlock: false,
            blockAppointment: false,
            practiceOutsideAvailableTiming: false,
            timeToCheckBlock: {
                schedule_at: moment(),
                slot: 10,
            }

        }
        this.changeRedirect = this.changeRedirect.bind(this);
        this.loadDoctors = this.loadDoctors.bind(this);
        this.loadProcedureCategory = this.loadProcedureCategory.bind(this);
        this.loadTreatmentNotes = this.loadTreatmentNotes.bind(this);
        this.searchPatient = this.searchPatient.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadAppointment = this.loadAppointment.bind(this);
        this.loadPracticeTiming = this.loadPracticeTiming.bind(this);
    }

    componentDidMount() {
        this.loadClinics();
        this.handlePatientSelect(this.props.user.id);
        // this.findOutsideDoctorTiming();

    }

    setClinic = (id) => {
        let that = this;

        this.setState({
            currentClinic: id,
            doctorOutsideAvailableTiming: false
        }, function () {
            that.loadDoctors();
            that.loadPracticeTiming();
            this.props.form.setFieldsValue({
                doctor: '',
            });
            if (that.props.match.params.appointmentid) {
                that.loadAppointment();
            }
        })
    }


    loadClinics = () => {
        let that = this;
        let successFn = function (data) {
            that.setState({
                clinics: data
            })
        }
        let errorFn = function () {
        }
        getAPI(ALL_PRACTICE, successFn, errorFn);

    }

    loadAppointment() {
        let that = this;
        this.setState({
            loading: true,
        })
        let successFn = function (data) {
            that.setState({
                appointment: data,
                patientDetails: data.patient,
                loading: false,
            });
            console.log("appointment list");
        }

        let errorFn = function () {
            that.setState({
                loading: false,
            })
        }
        getAPI(interpolate(APPOINTMENT_API, [this.state.currentClinic]), successFn, errorFn);

    }

    loadDoctors() {
        let that = this;
        let successFn = function (data) {
            let doctor = [];
            data.staff.forEach(function (usersdata) {
                if (usersdata.role == DOCTORS_ROLE) {
                    doctor.push(usersdata);

                }
            });
            that.setState(function (prevState) {
                let selectedDoctor = null
                if (doctor.length) {
                    selectedDoctor = doctor[0].id
                }
                return {
                    practice_doctors: doctor,
                    timeToCheckBlock: {...prevState.timeToCheckBlock, doctor: selectedDoctor}
                }
            }, function () {
                that.findBlockedTiming();
                // that.loadDoctorsTiming();
                that.setBlockedTiming();
            });
        }
        let errorFn = function () {
        };
        getAPI(interpolate(PRACTICESTAFF, [this.state.currentClinic ? this.state.currentClinic : null]), successFn, errorFn);
    }

    loadProcedureCategory() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                procedure_category: data
            })
            // console.log("category",that.state.procedure_category);

        }
        let errorFn = function () {

        }
        getAPI(interpolate(PROCEDURE_CATEGORY, [this.state.currentClinic]), successFn, errorFn)
    }

    loadTreatmentNotes() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                treatmentNotes: data
            })

        }
        let errorFn = function () {

        }
        getAPI(interpolate(EMR_TREATMENTNOTES, [this.state.currentClinic]), successFn, errorFn)
    }

    loadAppointmentCategories() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                appointmentCategories: data
            })

        }
        let errorFn = function () {

        }
        getAPI(interpolate(APPOINTMENT_CATEGORIES, [this.state.currentClinic]), successFn, errorFn)
    }

    changeRedirect() {
        var redirectVar = this.state.redirect;
        this.setState({
            redirect: !redirectVar,
        });
    }

    searchPatient(value) {
        // console.log(e.target.value);
        let that = this;
        let successFn = function (data) {
            if (data) {
                that.setState({
                    patientListData: data
                })
                // console.log("list",that.state.patientListData);
            }
        };
        let errorFn = function () {
        };
        getAPI(interpolate(SEARCH_PATIENT, [value]), successFn, errorFn);
    }

    handleSubmit = (e) => {
        let that = this;
        let patient = {};
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let formData = {...values};
                formData.patient = {user: {}};
                if (!this.state.patientDetails) {
                    formData.patient.user.first_name = formData.patient_name;
                    formData.patient.user.email = formData.patient_email;
                    formData.patient.user.mobile = formData.patient_mobile;
                    formData.patient_email = undefined;
                    formData.patient_name = undefined;
                    formData.patient_mobile = undefined;
                } else {
                    formData.patient = this.state.patientDetails;
                }
                formData.practice = that.state.currentClinic;
                console.log(formData);
                let successFn = function (data) {
                    if (data) {
                        // console.log(data)
                        displayMessage(SUCCESS_MSG_TYPE, "Appointment Created Successfully");
                        that.props.history.push("/patient/" + that.props.user.id + "/appointments")
                    }
                };
                let errorFn = function () {

                };
                // console.log("null",this.state.patientDetails );
                if (this.state.appointment) {
                    putAPI(interpolate(APPOINTMENT_API, [this.state.appointment.id]), formData, successFn, errorFn);
                } else {
                    postAPI(ALL_APPOINTMENT_API, formData, successFn, errorFn);
                }
            }
        });

    }
    handlePatientSelect = (event) => {
        console.log(event);
        if (event) {
            let that = this;
            let successFn = function (data) {
                that.setState({
                    patientDetails: data

                });
                // console.log("event",that.state.patientDetails);
            };
            let errorFn = function () {
            };
            getAPI(interpolate(PATIENT_PROFILE, [event]), successFn, errorFn);
        }
    }
    handleChange = (event) => {
        this.setState({})

        // this.setState({ value: event.target.value });
    };
    handleClick = (e) => {

        this.setState({
            patientDetails: null
        })

    }
    loadPracticeTiming = () => {
        var that = this;
        let successFn = function (data) {
            let dataObject = {};
            if (data.length)
                dataObject = data[0];
            let timing = {};
            DAY_KEYS.forEach(function (dayKey) {
                timing[dayKey] = {};
                if (dataObject.visting_hour_same_week) {
                    timing[dayKey].startTime = moment(dataObject.first_start_time, 'HH:mm:ss');
                    timing[dayKey].endTime = moment(dataObject.second_end_time, 'HH:mm:ss');
                    if (dataObject.is_two_sessions) {
                        timing[dayKey].lunch = true;
                        timing[dayKey].lunchStartTime = moment(dataObject.first_end_time, 'HH:mm:ss');
                        timing[dayKey].lunchEndTime = moment(dataObject.second_start_time, 'HH:mm:ss');
                    } else {
                        timing[dayKey].lunch = false
                    }
                } else if (dataObject[dayKey]) {
                    timing[dayKey].startTime = moment(dataObject[`first_start_time_${dayKey}`], 'HH:mm:ss');
                    timing[dayKey].endTime = moment(dataObject[`second_end_time_${dayKey}`], 'HH:mm:ss');
                    if (dataObject[`is_two_sessions_${dayKey}`]) {
                        timing[dayKey].lunch = true;
                        timing[dayKey].lunchStartTime = moment(dataObject[`first_end_time_${dayKey}`], 'HH:mm:ss');
                        timing[dayKey].lunchEndTime = moment(dataObject[`second_start_time_${dayKey}`], 'HH:mm:ss');
                    } else {
                        timing[dayKey].lunch = false
                    }
                } else {
                    timing[dayKey] = null
                }
            });
            that.setState({
                calendarTimings: {...timing},
            }, function () {
                that.findOutsidePracticeTiming();
            });
        };
        let errorFn = function () {
            that.setState({
                calendarTimings: {}
            })
        };
        getAPI(interpolate(CALENDER_SETTINGS, [this.state.currentClinic ? this.state.currentClinic : null]), successFn, errorFn);
    }
    loadDoctorsTiming = () => {
        let that = this;
        let successFn = function (data) {
            let dataObject = {};
            if (data.length)
                dataObject = data[0];
            let timing = {};
            DAY_KEYS.forEach(function (dayKey) {
                timing[dayKey] = {};
                if (dataObject.visting_hour_same_week) {
                    timing[dayKey].startTime = moment(dataObject.first_start_time, 'HH:mm:ss');
                    timing[dayKey].endTime = moment(dataObject.second_end_time, 'HH:mm:ss');
                    if (dataObject.is_two_sessions) {
                        timing[dayKey].lunch = true;
                        timing[dayKey].lunchStartTime = moment(dataObject.first_end_time, 'HH:mm:ss');
                        timing[dayKey].lunchEndTime = moment(dataObject.second_start_time, 'HH:mm:ss');
                    } else {
                        timing[dayKey].lunch = false
                    }
                } else if (dataObject[dayKey]) {
                    timing[dayKey].startTime = moment(dataObject[`first_start_time_${dayKey}`], 'HH:mm:ss');
                    timing[dayKey].endTime = moment(dataObject[`second_end_time_${dayKey}`], 'HH:mm:ss');
                    if (dataObject[`is_two_sessions_${dayKey}`]) {
                        timing[dayKey].lunch = true;
                        timing[dayKey].lunchStartTime = moment(dataObject[`first_end_time_${dayKey}`], 'HH:mm:ss');
                        timing[dayKey].lunchEndTime = moment(dataObject[`second_start_time_${dayKey}`], 'HH:mm:ss');
                    } else {
                        timing[dayKey].lunch = false
                    }
                } else {
                    timing[dayKey] = null
                }
            });
            that.setState({
                doctorTimings: {...timing},
            }, function () {
                that.findOutsideDoctorTiming();
            });
        }
        let errorFn = function () {

        };
        if (that.state.timeToCheckBlock.doctor)
            getAPI(interpolate(DOCTOR_VISIT_TIMING_API, [this.state.currentClinic]), successFn, errorFn, {
                doctor: that.state.timeToCheckBlock.doctor
            });
    }
    setBlockedTiming = (type, value) => {
        console.log("form ", type, value)
        let that = this;
        if (type) {
            this.setState(function (prevState) {
                return {
                    timeToCheckBlock: {...prevState.timeToCheckBlock, [type]: value}
                }
            }, function () {
                that.loadAppointmentList();
                that.findBlockedTiming();
                that.findOutsidePracticeTiming();
                if (type == 'doctor') {
                    that.loadDoctorsTiming();
                } else {
                    that.findOutsideDoctorTiming();
                }

            })
        }
    }

    findBlockedTiming = () => {
        let that = this;
        let successFn = function (data) {
            data.forEach(function (blockRow) {
                if (blockRow.doctor == null) {
                    that.setState({
                        practiceBlock: true
                    });
                } else if (blockRow.doctor == that.props.timeToCheckBlock.doctor) {
                    that.setState({
                        doctorBlock: true
                    });
                }
            });
        }
        let errorFn = function () {

        }
        getAPI(BLOCK_CALENDAR, successFn, errorFn, {
            practice: this.state.currentClinic ? this.state.currentClinic : null,
            cal_fdate: moment(that.state.timeToCheckBlock.schedule_at).format(),
            cal_tdate: moment(that.state.timeToCheckBlock.schedule_at).add(that.state.timeToCheckBlock.slot, 'minutes').format()
        })
    }
    findOutsidePracticeTiming = () => {
        let that = this;
        let flag = true;
        if (that.state.timeToCheckBlock.schedule_at && that.state.timeToCheckBlock.slot && that.state.calendarTimings) {
            let schedule_at = that.state.timeToCheckBlock.schedule_at;
            let calendarTimings = that.state.calendarTimings;
            let dayValue = moment(schedule_at).isValid() ? moment(schedule_at).format('dddd').toLowerCase() : null;
            /**
             * Checking for Calendar Clinic Timings
             * */
            if (calendarTimings && dayValue && calendarTimings[dayValue]) {
                let daysTimings = calendarTimings[dayValue];
                if (daysTimings.lunch) {
                    if (
                        (moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') <= daysTimings.startTime.format('HH:mm:ss')
                            || moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') > daysTimings.endTime.format('HH:mm:ss')
                        ) || (
                            moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') < daysTimings.lunchEndTime.format('HH:mm:ss')
                            && moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') >= daysTimings.lunchStartTime.format('HH:mm:ss')
                        )
                    ) {
                        flag = false;
                    }
                } else {
                    if (moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') <= daysTimings.startTime.format('HH:mm:ss') || moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') > daysTimings.endTime.format('HH:mm:ss')) {
                        flag = false;
                    }
                }
            } else if (dayValue && !calendarTimings[dayValue]) {
                /**
                 * If the practice isnot opening for the day
                 * */
                flag = false;
            }

        }
        that.setState({
            practiceOutsideAvailableTiming: !flag
        })
    }
    findOutsideDoctorTiming = () => {
        let that = this;
        let flag = true;
        if (that.state.timeToCheckBlock.schedule_at && that.state.timeToCheckBlock.slot && this.state.doctorTimings) {
            let schedule_at = that.state.timeToCheckBlock.schedule_at;
            let calendarTimings = that.state.doctorTimings;
            let dayValue = moment(schedule_at).isValid() ? moment(schedule_at).format('dddd').toLowerCase() : null;
            /**
             * Checking for Calendar Clinic Timings
             * */
            if (calendarTimings && dayValue && calendarTimings[dayValue]) {
                let daysTimings = calendarTimings[dayValue];
                if (daysTimings.lunch) {
                    if (
                        (moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') <= daysTimings.startTime.format('HH:mm:ss')
                            || moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') > daysTimings.endTime.format('HH:mm:ss')
                        ) || (
                            moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') < daysTimings.lunchEndTime.format('HH:mm:ss')
                            && moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') >= daysTimings.lunchStartTime.format('HH:mm:ss')
                        )
                    ) {
                        flag = false;
                    }
                } else {
                    if (moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') <= daysTimings.startTime.format('HH:mm:ss') || moment(schedule_at, 'HH:mm:ss').format('HH:mm:ss') > daysTimings.endTime.format('HH:mm:ss')) {
                        flag = false;
                    }
                }
            } else if (dayValue && calendarTimings && !calendarTimings[dayValue]) {
                /**
                 * If the practice isnot opening for the day
                 * */
                flag = false;
            }
        }
        that.setState({
            doctorOutsideAvailableTiming: !flag
        })
    }

    loadAppointmentList() {
        let that = this;
        let successFn = function (data) {
            if (data.length) {
                that.setState({
                    blockAppointment: true,
                })
            } else {
                that.setState({
                    blockAppointment: false,
                })
            }
        }
        let errorFn = function (data) {
        }
        let apiParams = {
            start_time: moment(this.state.timeToCheckBlock.schedule_at).format(),
            end_time: moment(this.state.timeToCheckBlock.schedule_at).add(this.state.timeToCheckBlock.slot, 'minutes').format(),
            doctor: this.state.timeToCheckBlock.doctor,
        }
        getAPI(interpolate(APPOINTMENT_SCHEDULE, [this.state.currentClinic ? this.state.currentClinic : null]), successFn, errorFn, apiParams);
    }

    render() {
        const that = this;
        const formItemLayout = (this.props.formLayout ? this.props.formLayout : {
            labelCol: {span: 6},
            wrapperCol: {span: 14},
        });
        const formPatients = (this.props.formLayout ? this.props.formLayout : {
            labelCol: {span: 6},
            wrapperCol: {span: 14},
        });
        const {getFieldDecorator} = this.props.form;
        console.log(this.state.practice_doctors)

        const procedureOption = []
        if (this.state.procedure_category) {
            this.state.procedure_category.forEach(function (drug) {
                procedureOption.push({label: (drug.name), value: drug.id});
            })
        }
        const doctorOption = []
        if (this.state.practice_doctors.length) {
            this.state.practice_doctors.forEach(function (drug) {
                doctorOption.push({label: (drug.user.first_name + "(" + drug.user.email + ")"), value: drug.id});
            })
        }
        const treatmentNotesOption = [];
        if (this.state.treatmentNotes) {
            this.state.treatmentNotes.forEach(function (drug) {
                treatmentNotesOption.push({label: drug.name, value: drug.id});
            })
        }
        // console.log("doctor list",JSON.stringify(this.state.treatmentNotes));
        const categoryOptions = [];
        if (this.state.appointmentCategories) {
            this.state.appointmentCategories.forEach(function (category) {
                categoryOptions.push({label: category.name, value: category.id});
            })
        }
        let appointmentTime = this.state.appointment ? this.state.appointment.shedule_at : this.props.startTime;
        if (!appointmentTime) {
            appointmentTime = new moment(new Date()).format();
            console.log(appointmentTime);
        }
        const fields = [];
        return <Card loading={this.state.loading}>
            <Form onSubmit={this.handleSubmit}>
                {this.props.title ? <h2>{this.props.title}</h2> : null}
                <Form.Item label="Clinic" {...formItemLayout}>
                    {getFieldDecorator('practice', {
                        rules: [{required: true, message: 'this field required!'}],
                    })
                    (<Select onChange={(value) => that.setClinic(value)}>
                        {that.state.clinics.map(room => <Select.Option
                            value={room.id}>{room.name}</Select.Option>)}
                    </Select>)
                    }
                    {this.state.practiceOutsideAvailableTiming ?
                        <Alert message="Selected time is outside available clinic time!!" type="warning"
                               showIcon/> : null}
                    {this.state.practiceBlock ?
                        <Alert message="Selected time is blocked in this clinic !!" type="warning"
                               showIcon/> : null}
                </Form.Item>
                <FormItem key="schedule_at" label="Appointment Schedule" {...formItemLayout}>
                    {getFieldDecorator("schedule_at",
                        {
                            initialValue: appointmentTime ? moment(appointmentTime) : null,
                            rules: [{required: true, message: REQUIRED_FIELD_MESSAGE}],
                        })(
                        <DatePicker format="YYYY/MM/DD HH:mm"
                                    onChange={(value) => this.setBlockedTiming("schedule_at", value)}/>
                    )}

                </FormItem>
                <FormItem key="slot"
                          {...formItemLayout}
                          label="Time Slot">
                    {getFieldDecorator("slot", {
                        initialValue: this.state.appointment ? this.state.appointment.slot : 10,
                        rules: [{required: true, message: REQUIRED_FIELD_MESSAGE}],
                    })(
                        <InputNumber min={1} disabled={true}/>
                    )}
                    <span className="ant-form-text">mins</span>
                    {this.state.blockAppointment ?
                        <Alert message="Selected time slot not available for appointment."
                               type="warning"
                               showIcon/> : null}
                </FormItem>


                {this.state.patientDetails ?
                    <FormItem key="id" value={this.state.patientDetails.id} {...formPatients} label={'Patient'}>
                        <Card bordered={false} style={{background: '#ECECEC'}}>
                            <Meta
                                avatar={<Avatar style={{backgroundColor: '#ffff'}}
                                                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>}
                                title={this.state.patientDetails.user.first_name}
                                description={this.state.patientDetails.user.mobile}

                            />

                            {/*<Button type="primary" style={{float: 'right'}} onClick={this.handleClick}>Add New*/}
                            {/*    Patient</Button>*/}
                        </Card>
                    </FormItem>
                    : <div/>}
                <FormItem key="doctor" {...formItemLayout} label="Doctor">
                    {getFieldDecorator("doctor", {
                        initialValue: this.state.appointment ? this.state.appointment.doctor : '',
                        rules: [{required: true, message: REQUIRED_FIELD_MESSAGE}],
                    })(
                        <Select placeholder="Doctor" onChange={(value) => this.setBlockedTiming("doctor", value)}>
                            {doctorOption.map((option) => <Select.Option
                                value={option.value}>{option.label}</Select.Option>)}
                        </Select>
                    )}
                    {this.state.doctorBlock ?
                        <Alert message="Selected time is blocked for selected doctor in this clinic!!"
                               type="warning"
                               showIcon/> : null}
                    {this.state.doctorOutsideAvailableTiming ?
                        <Alert message="Selected time is out of doctor's visit time in this clinic!!"
                               type="warning"
                               showIcon/> : null}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit"
                            disabled={this.state.practiceBlock || this.state.blockAppointment || this.state.doctorBlock || this.state.practiceOutsideAvailableTiming || this.state.doctorOutsideAvailableTiming}>Submit</Button>

                    {that.props.history ?
                        <Button style={{margin: 5}} onClick={() => that.props.history.goBack()}>
                            Cancel
                        </Button> : null}
                </FormItem>
            </Form>
        </Card>
    }
}
