import React from "react";
import {Checkbox,Table,Button,Card,Icon,Tag,Tooltip} from "antd";
import {
    PROCEDURE_CATEGORY,
    TREATMENTPLANS_PDF,
    TREATMENTPLANS_API
} from "../../../constants/api";
import {getAPI, interpolate, displayMessage, putAPI, postAPI} from "../../../utils/common";
import moment from "moment";
import InfiniteFeedLoaderButton from "../../common/InfiniteFeedLoaderButton";
import {BACKEND_BASE_URL} from "../../../config/connect";

class PatientTreatmentPlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: this.props.currentPatient,
            active_practiceId: this.props.active_practiceId,
            treatmentPlans: [],
            procedure_category: null,
            incompletedTreatmentPlans: [],
            loading: true,
            selectedTreatments: {}
        }
        this.loadTreatmentPlans = this.loadTreatmentPlans.bind(this);
        this.loadProcedureCategory = this.loadProcedureCategory.bind(this);

    }

    componentDidMount() {
        this.loadTreatmentPlans();
        // this.loadProcedureCategory();
    }

    loadTreatmentPlans() {
        let incompleted = [];
        let that = this;
        let successFn = function (data) {
            that.setState(function (prevState) {
                return {
                    treatmentPlans: [...prevState.treatmentPlans, ...data.results],
                    next: data.next,
                    loading: false
                }
            })
            data.results.forEach(function (treatment_plans) {
                if (!treatment_plans.is_completed) {
                    incompleted.push(treatment_plans)
                }
            })
            that.setState(function (prevState) {
                return {
                    incompletedTreatmentPlans: [...prevState.incompletedTreatmentPlans, ...incompleted],
                    loading: false
                }
            })
        }
        let errorFn = function () {
            that.setState({
                loading: false
            })

        }
        getAPI(interpolate(TREATMENTPLANS_API, [this.props.currentPatient.id, null]), successFn, errorFn)
    }


    loadProcedureCategory() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                procedure_category: data,
                loading: false
            })

        }
        let errorFn = function () {
            that.setState({
                loading: false
            })

        }
        getAPI(interpolate(PROCEDURE_CATEGORY, [this.props.active_practiceId]), successFn, errorFn)
    }


    treatmentCompleteToggle(id, option) {
        this.setState(function (prevState) {
            return {selectedTreatments: {...prevState.selectedTreatments, [id]: !!option}}
        });
    }

    loadPDF(id) {
        let that = this;
        let successFn = function (data) {
            if (data.report)
                window.open(BACKEND_BASE_URL + data.report);
        }
        let errorFn = function () {

        }
        getAPI(interpolate(TREATMENTPLANS_PDF, [id]), successFn, errorFn);
    }
    render() {
        console.log("treatment",this.state.treatmentPlans)
        let that=this;
        const procedures = {}
        if (this.state.procedure_category) {
            this.state.procedure_category.forEach(function (procedure) {
                procedures[procedure.id] = (procedure.name)
            })
        }

        const columns = [{
            title: 'Procedure',
            key: 'procedure',
            dataIndex:'procedure.name'
        },{
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        }, {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
        }, {
            title: 'Cost per  Unit',
            dataIndex: 'cost',
            key: 'cost',
        }, {
            title: 'Notes',
            dataIndex: 'default_notes',
            key: 'default_notes',
        }];

            return  <div>
            <Card
                title={this.state.currentPatient ? this.state.currentPatient.user.first_name + " TreatmentPlans" : "TreatmentPlans"}/>
                {this.state.treatmentPlans.map((treatment) => <Card bodyStyle={{padding: 0}}
                                                                    style={{marginTop: 15}}>
                        <div style={{padding: 16}}>
                            <h4>{treatment.date ? moment(treatment.date).format('ll') : null}
                                <Button
                                    size={"small"}
                                    style={{float: 'right'}}>
                                    <a onClick={() => this.loadPDF(treatment.id)}><Icon type="printer"/></a>

                                </Button>
                            </h4>

                        </div>
                        <Table loading={this.state.loading} columns={columns}
                            dataSource={treatment.treatment_plans}
                            footer={() => treatmentFooter(treatment)}
                            pagination={false}
                            key={treatment.id}/>

                </Card>
            )}
            <InfiniteFeedLoaderButton loaderFunction={() => this.loadTreatmentPlans(that.state.next)}
                                      loading={this.state.loading}
                                      hidden={!this.state.next}/>
        </div>


            // <div>
            //     <Card
            //         title={this.state.currentPatient ? this.state.currentPatient.user.first_name + " TreatmentPlans" : "TreatmentPlans"}>
            //         {this.state.treatmentPlans.map((treatment) =>
            //             <Table loading={this.state.loading} columns={columns} dataSource={treatment.treatment_plans}  pagination={false}/>
            //         )}
            //     </Card>

            // </div>


    }
}

export default PatientTreatmentPlans;

function treatmentFooter(presc) {
    if (presc) {

        return <div>
            {presc.doctor ? <Tag color={presc.doctor ? presc.doctor.calendar_colour : null}>
                <b>{"prescribed by  " + presc.doctor.user.first_name} </b>
            </Tag> : null}
            {presc.practice ? <Tag style={{float: 'right'}}>
                <Tooltip title="Practice Name">
                    <b>{presc.practice.name} </b>
                </Tooltip>
            </Tag> : null}
        </div>
    }
    return null
}
