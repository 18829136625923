
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const AUTH_TOKEN = 'token';
export const ROLE = 'role';
export const CURRENT_PRACTICE = 'current_practice';
export const OLD_PASSWORD = 'old_password';
export const NEW_PASSWORD = 'new_password';
export const PHONE = 'phone';
export const OTP = 'otp'
