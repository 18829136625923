import React from "react";
import {Avatar, Dropdown, Icon, Select, Layout, Menu} from "antd";
import {Route, Link, Switch} from 'react-router-dom';
import Applogo from '../../assets/img/kidneycarelogo.png'
const {Header} = Layout;

class AppHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const userMenu = (
            <Menu>
                {/*<Menu.Item>*/}
                {/*UserName*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="profile">
                    <Link to="/profile">Profile</Link>
                </Menu.Item>
                {/*<Menu.Item>*/}
                {/*<Link to="/settings">Settings</Link>*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="logout">
                    <a onClick={this.props.logout}>Log out</a>
                </Menu.Item>
            </Menu>
        );
        return <Header style={{background: '#fff', padding: 0, boxShadow: '0 2px 4px 0 rgba(38,50,69,.2)', zIndex: 1}}>
            {/* <div style={{float: 'left', margin: '0px 20px'}}>
                <Icon
                    className="trigger"
                    type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={()=>this.props.toggleSider(!this.props.collapsed)}
                />
            </div> */}
             {/* <div style={{float: 'left', margin: '0px 20px'}}>
                <img src={Applogo} alt="" style={{width: '100%', padding: '20px'}}/>
             </div> */}
            <div style={{float: 'left', margin: '0px 20px', display: 'inline-flex'}}>
                <img src={Applogo} alt="" style={{maxWidth: '100%', maxHeight: 60,padding:5}}/> <h2
                style={{lineHeight:'50px'}}>&nbsp;&nbsp;&nbsp;Patient&nbsp;Panel</h2>
            </div>
            <Menu mode="horizontal"
                  style={{lineHeight: '64px'}}>

                <Menu.Item key="4">
                    {/* <Link to="/">
                        <div className="logo"/>
                    </Link> */}

                </Menu.Item>

                {/*<Menu.Item key="3"><Search*/}
                {/*placeholder="Search"*/}
                {/*onSearch={value => console.log(value)}*/}
                {/*style={{width: 200}}*/}
                {/*/></Menu.Item>*/}

                <div style={{float: 'right', margin: '0px 20px'}}>
                    <Dropdown overlay={userMenu} placement="bottomRight">
                        <Avatar style={{backgroundColor: '#87d068'}} icon="user"/>
                    </Dropdown>
                </div>

            </Menu>
        </Header>
    }
}

export default AppHeader;
