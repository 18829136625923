import {BACKEND_BASE_URL} from "../config/connect";

/***
 * API Connection URLs
 * */
export const API_URL = BACKEND_BASE_URL + 'erp-api';
export const FILE_UPLOAD_API = 'blogImage/';
export const FILE_UPLOAD_BASE64 = 'blogImage/create_with_base64/';
export const LOGIN_SEND_OTP = 'patient_login/send_otp/';
export const LOGIN_RESEND_OTP = 'patient_login/resend_otp/';
export const SIGNUP_URL = '';
export const RESET_PASSWORD = 'users/reset_password/';
export const CHANGE_PASSWORD = 'users/passwordchange/';
export const RESET_PASSWORD_MAIL = 'users/staff_reset_mail/';
export const LOGIN_URL = 'patient_login/verify_otp/';
export const USER_DATA = 'users/user_clone/';
export const PRACTICESTAFF = 'clinics/%s/practice_staff/';
export const USER_PRACTICE_PERMISSIONS = 'user_permissions/?user=%s&practice=%s';
export const SET_USER_PERMISSION = 'user_permissions/';
export const SET_SPECIFIC_USER_PERMISSION = 'user_permissions/%s/';
export const ALL_PRACTICE = 'clinics/';
export const PRACTICE = 'clinics/%s/';
export const PRACTICE_DELETE = 'clinics/%s/delete_clinic/';
export const TAXES = 'clinics/%s/taxes/';
export const OFFERS = 'clinics/%s/offers/';
export const PAYMENT_MODES = 'clinics/%s/payment_modes/';
export const PROCEDURE_CATEGORY = 'clinics/%s/procedure_category/';
export const DOCTOR_VISIT_TIMING_API = 'clinics/%s/doctor_timing/';


export const EMR_DIAGNOSES = 'clinics/%s/diagnoses/';
export const EMR_COMPLAINTS = 'clinics/%s/complaints/';
export const EMR_OBSERVATIONS = 'clinics/%s/observations/';
export const EMR_TREATMENTNOTES = 'clinics/%s/treatmentnotes/';
export const EMR_INVESTIGATIONS = 'clinics/%s/investigations/';
export const EMR_FILETAGS = 'clinics/%s/filetags/';
export const APPOINTMENT_CATEGORIES = 'clinics/%s/appointment_category/';
export const ALL_PRACTICE_STAFF = 'staff/';
export const SINGLE_PRACTICE_STAFF_API = 'staff/%s/';

export const PATIENTS_LIST = 'patients/';
export const PATIENT_PROFILE = 'patients/%s/';
export const STAFF_ROLES = 'staff/roles/';
export const MEDICAL_HISTORY = 'patients/history/?id=%s'
export const MEDICAL_CERTIFICATE_API = 'patients/medical_certificate/?patient=%s';
export const PATIENT_APPOINTMENT_REPORTS ='patients/%s/patient_appointments/'

// export const PATIENT_MEDICAL_HISTORY = 'patients/history/?id=%s';
export const PATIENT_FILES = 'patients/files/?patient=%s';  //changed id to patient
export const ALL_PATIENT_FILES = 'patients/files/';
export const EXPENSE_TYPE = 'clinics/%s/expense_type/';
export const DRUG_CATALOG = 'clinics/%s/drugcatalog/';
export const SINGLE_DRUG_CATALOG = 'clinics/%s/drugcatalog/?id=%s';
export const LABTEST_API = 'clinics/%s/labtest/';
export const COMMUNICATONS_API = 'clinics/%s/communications/';
export const CALENDER_SETTINGS = 'clinics/%s/calender_settings/';
export const ALL_APPOINTMENT_API = 'appointment/';
export const APPOINTMENT_API = 'appointment/?patient=%s';
export const APPOINTMENT_PERPRACTICE_API = 'appointment/?id=%s';
export const SINGLE_APPOINTMENT_PERPRACTICE_API = 'appointment/?id=%s&patient=%s';
export const PATIENT_GROUPS = 'patients/group/?id=%s';
export const VITAL_SIGNS_API = 'patients/vital_sign/?patient=%s'; //changed id to patient
export const PRESCRIPTIONS_API = 'patients/prescriptions/?patient=%s';  //changed id to patient
export const SINGLE_REATMENTPLANS_API = 'patients/procedure/?id=%s';
export const PATIENT_TIMELINE_API = 'patients/%s/combine_patient_report/';
export const PATIENT_TIMELINE_PDF = 'patients/%s/timeline_pdf/';
export const BLOCK_CALENDAR = 'appointment/block_calendar/';
export const APPOINTMENT_SCHEDULE='appointment/?practice=%s';

/*DELETE OBJECT*/
export const TREATMENTPLANS_API = 'patients/procedure/?patient=%s&complete=%s'; //changed id to patient
export const ALL_PRESCRIPTIONS_API = 'patients/prescriptions/%s/';
export const INVOICES_API = 'invoice/?patient=%s'; //changed id to patient
export const CREATE_OR_EDIT_INVOICES = 'invoice/';
export const INVOICE_RETURN_API = 'return/';
export const PATIENT_CLINIC_NOTES_API = 'patients/clinic_notes/?patient=%s'; //changed id to patient
export const PATIENT_PAYMENTS_API = 'payment/';
export const PRACTICE_PRINT_SETTING_API = 'clinics/%s/practice_print_settings/?type=%s&sub_type=%s';
export const PATIENT_COMMUNICATION_HISTORY_API = 'users/sms_status_update/?user=%s';
export const PRESCRIPTION_TEMPLATE = "clinics/%s/prescription_template/";
export const UNPAID_PRESCRIPTIONS = "patients/unpaid_prescriptions/?id=%s";
//search and advanced search API
export const SEARCH_PATIENT = 'patients/search/?name=%s';


//extra data
export const EXTRA_DATA = 'clinics/extra_data';
export const COUNTRY ='patients/country';
export const STATE ='patients/state';
export const CITY ='patients/city';
/**
//reports

export const APPOINTMENT_REPORTS = 'clinics/%s/appointment_report/?patient=%s'
export const EXPENSE_REPORT = 'clinics/%s/expense_report/?%s'
export const INVOICE_REPORTS = 'clinics/%s/invoice_report/?%s'
export const PATIENTS_REPORTS = 'clinics/%s/patients_report/?%s'
export const PAYMENTS_REPORTS = 'clinics/%s/payments_report/?%s'
export const TREATMENT_REPORTS = 'clinics/%s/treatment_report/?%s'
export const DRUG_TYPE_API = 'drug_type/';
export const DRUG_UNIT_API = '';

/**
 * INVENTORY API
 * */

export const VITAL_SIGN_PDF = 'patients/vital_sign_pdf/?id=%s';
export const EXPENSES_API = "expenses/";
export const SINGLE_EXPENSES_API = "expenses/%s/";
export const VENDOR_API = "clinics/%s/vendor/";
export const SINGLE_VENDOR_API = "vendor/%s/";
export const ACTIVITY_API = "activity/";
export const SINGLE_ACTIVITY_API = "activity/%s/";
export const LAB_API = "lab/";
export const SINGLE_LAB_API = "lab/%s/";
export const MANUFACTURER_API = "manufacturer/";
export const SINGLE_MANUFACTURER_API = "manufacturer/%s/";
export const INVENTORY_ITEM_API = "inventory_item/";
export const SINGLE_INVENTORY_ITEM_API = "inventory_item/%s/";
export const INVENTORY_API = "inventory/";
export const SINGLE_INVENTORY_API = "inventory/%s/";
export const ITEM_TYPE_STOCK = "item_type_stock/";
export const SINGLE_ITEM_TYPE_STOCK = "item_type_stock/%s/";
export const STOCK_ENTRY = "stock_entry/";
export const BULK_STOCK_ENTRY = "stock_entry/bulk/";


/**
 * MLM API
 * */
export const ROLE_COMMISION = "role_commission/";
export const PRODUCT_LEVEL = "product_level";
export const PRODUCT_MARGIN = "product_margin/";
export const SINGLE_PRODUCT_MARGIN = "product_margin/%s/";
export const GENERATE_MLM_COMMISSON = 'product_margin/generate_mlm/';
export const TREATMENTPLANS_PDF = 'patients/treatment_plan_pdf/?id=%s';
export const CLINIC_NOTES_PDF = 'patients/clinic_notes_pdf/?id=%s';
export const AGENT_ROLES ='role_commission/agent_roles/';
export const MY_AGENTS = 'patients/%s/my_agents/';
export const AGENT_WALLET ='patient_wallet/?patient=%s';
export const WALLET_LEDGER = 'wallet_ledger/?patient=%s';

/**
 * Reservation System
 * */
export const ROOM_TYPE = 'clinics/%s/room_types/';
export const BED_PACKAGES = 'clinics/%s/bed_packages/';
export const MEDICINE_PACKAGES = 'clinics/%s/medicine_packages/';
export const BOOK_SEAT = 'clinics/%s/seat_booking/';
export const CHECK_SEAT_AVAILABILITY = 'clinics/%s/seat_availability/';
export const BED_BOOKING_REPORTS ='clinics/seat_booking_report/';
export const ACCEPT_PAYMENT = 'clinics/accept_payments/';
export const DISEASE_LIST = 'clinics/%s/other_diseases/';


/** Agent Tree***/
export const AGENT_TREE='patients/%s/agents_chain/';

/**suggestions**/
export const SUGGESTIONS='suggestions/';

export const PATIENT_LEDGER = 'patients/%s/ledger/';