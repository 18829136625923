import React from "react";

import {Affix, Alert, Button, Card, Col, Divider, Icon, Menu, Row, Spin, Table, Tag, Tooltip} from "antd";
import {getAPI, interpolate} from "../../../utils/common";
import {INVOICES_API, PATIENT_PAYMENTS_API, PAYMENT_MODES} from "../../../constants/api";
import moment from "moment";
import {Link} from "react-router-dom";


class PatientPayments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            active_practiceId: this.props.active_practiceId,
            loading: true
        }

    }

    componentDidMount() {
        // this.loadPaymentModes();
        this.loadPayments();
        // this.loadInvoices();
    }


    loadPayments() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                payments: data.results,
                loading: false
            })
        }
        let errorFn = function () {
            that.setState({
                loading: false
            })

        };
        let reqData={
            patient: this.props.currentPatient.id,
            page:1,
            page_size:1,
        };
        getAPI(PATIENT_PAYMENTS_API, successFn, errorFn, reqData);
    }

    // loadInvoices() {
    //     let that = this;
    //     let successFn = function (data) {
    //         that.setState({
    //             invoices: data,
    //             loading: false
    //         })
    //     }
    //     let errorFn = function () {
    //         that.setState({
    //             loading: false
    //         })

    //     }
    //     getAPI(interpolate(INVOICES_API, [this.props.currentPatient.id]), successFn, errorFn);
    // }

    loadPaymentModes() {
        var that = this;
        let successFn = function (data) {
            that.setState({
                paymentModes: data,
                loading: false
            })
        };
        let errorFn = function () {
            that.setState({
                loading: false
            })
        };
        getAPI(interpolate(PAYMENT_MODES, [this.props.active_practiceId]), successFn, errorFn);
    }

    render() {
        const paymentmodes = {}
        if (this.state.paymentModes) {
            this.state.paymentModes.forEach(function (mode) {
                paymentmodes[mode.id] = mode.mode;
            })
        }
        const invoicelist = {}
        // if(this.state.invoices){
        //     this.state.invoices.forEach(function (mode) {
        //         invoicelist[mode.id]=mode.id;
        //     })
        // }
        const columns = [{
            title: 'Time',
            dataIndex: 'created_at',
            key: 'name',
            render: created_at => <span>{moment(created_at).format('LLL')}</span>,
        }, {
            title: 'Amount Paid	',
            dataIndex: 'amount',
            key: 'amount',
        }, {
            title: 'Invoices	',
            key: 'invoice	',
            render: (text, record) => (
                <span> {invoicelist[record.invoice]}</span>
            )
        }, {
            title: 'Mode of Payment ',
            key: 'payment_mode',
            render: (text, record) => (
                <span> {paymentmodes[record.payment_mode]}</span>
            )
        }];
        return <div>

            <Affix offsetTop={0}>
                <Card
                    bodyStyle={{padding: 0}}
                    title={"Latest Payments"}>
                </Card>

            </Affix>

            {this.state.payments.map(payment => PaymentCard(payment, this))}
            <Spin spinning={this.state.loading}>
                <Row/>
            </Spin>
        </div>


    }
}

export default PatientPayments;

const columns = [{
    title: 'INVOICE',
    dataIndex: 'invoice_id',
    key: 'invoice',
    render: invoice => <span>{invoice}</span>,
}, {
    title: 'Amount Paid',
    key: 'pay_amount',
    dataIndex: 'pay_amount'
}];

function PaymentCard(payment, that) {
    return <Card style={{marginTop: 10}}
                 bodyStyle={{padding: 0}}
                 title={(payment.patient_data && !that.props.currentPatient ?
                     <small>{payment.created_at ? moment(payment.created_at).format('lll') : null}
                         <Link to={"/patient/" + payment.patient_data.id + "/billing/payments"}>
                             &nbsp;&nbsp; {payment.patient_data.user.first_name} (ID: {payment.patient_data.id})&nbsp;
                         </Link>
                         <span>, {payment.patient_data.gender}</span></small>
                     : <small>{payment.created_at ? moment(payment.created_at).format('lll') : null}</small>)}
                >
        <Row gutter={8}>
            <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4} style={{padding: 10}}>
                {payment.is_cancelled ?
                    <Alert message="Cancelled" type="error" showIcon/> : null}
                <Divider style={{marginBottom: 0}}>{payment.payment_id}</Divider>
            </Col>
            <Col xs={24} sm={24} md={18} lg={20} xl={20} xxl={20}>

                <Table columns={columns}
                       pagination={false}
                       footer={() => PaymentFooter({practice: payment.practice_data})}
                       dataSource={payment.invoices}/>
            </Col>
        </Row>
    </Card>
}

function PaymentFooter(presc) {
    if (presc) {
        return <p>
            {presc.doctor ? <Tooltip title="Doctor"><Tag color={presc.doctor ? presc.doctor.calendar_colour : null}>
                <b>{"prescribed by  " + presc.doctor.user.first_name} </b>
            </Tag></Tooltip> : null}
            {presc.practice ? <Tag style={{float: 'right'}}>
                <Tooltip title="Practice Name">
                    <b>{presc.practice.name} </b>
                </Tooltip>
            </Tag> : null}
        </p>
    }
    return null
}
