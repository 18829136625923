import React from "react";
import {Card, Col, Icon, Layout, Row, Tag} from "antd";
import {Route, Switch} from "react-router";
import {BED_BOOKING_REPORTS} from "../../constants/api";
import {getAPI} from "../../utils/common";
import CustomizedTable from "../common/CustomizedTable";
import moment from "moment";
import InfiniteFeedLoaderButton from "../common/InfiniteFeedLoaderButton";
import BedandMedicineBookingDynamicForm from "./BedandMedicineBookingDynamicForm";

const {Content} = Layout;

export default class BookingHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bedBookingReports: [],
        };
        this.loadBedBookingReport = this.loadBedBookingReport.bind(this);
    }

    componentDidMount() {
        this.loadBedBookingReport();
    }

    loadBedBookingReport = (page = 1) => {
        let that = this;
        this.setState({
            loading: true
        })
        let successFn = function (data) {
            console.log(data);
            if (data.current == 1)
                that.setState({
                    bedBookingReports: data.results,
                    loading: false,
                    nextReport: data.next
                });
            else
                that.setState(function (prevState) {
                        return {
                            bedBookingReports: [...prevState.bedBookingReports, ...data.results],
                            loading: false,
                            nextReport: data.next
                        }
                    }
                );
        };
        let errorFn = function () {
            that.setState({
                loading: false
            })
        };
        getAPI(BED_BOOKING_REPORTS, successFn, errorFn, {
            page: page,
            patients: that.props.currentPatient.id
        });
    }

    render() {
        const columns = [{
            title: 'Bed Package',
            key: 'name',
            dataIndex: 'bed_package.name'
        }, {
            title: 'Medicine Package',
            key: 'medicine',
            dataIndex: 'medicines',
            render: (text, record) => <span>{text.map((item) =>
                <Tag>{item.name}</Tag>
            )}</span>
        }, {
            title: 'From ',
            key: 'from_date',
            render: (text, record) => (
                <span>
                {moment(record.from_date).format('LL')}
                  </span>
            ),
        }, {
            title: 'To ',
            key: 'to_date',
            render: (text, record) => (
                <span>
                {moment(record.to_date).format('LL')}
                  </span>
            ),
        }, {
            title: 'Seat/Bed Type',
            key: 'seat_type',
            dataIndex: 'seat_type'

        }, {
            title: 'Seat Number',
            key: 'seat_no',
            dataIndex: 'seat_no'

        },
            // {
            //     title:'No Of Room',
            //     key:'room',
            //     dataIndex:'bed_package.room'
            // },
            {
                title: 'Total price',
                key: 'total_price',
                dataIndex: 'total_price',
                render: (value) => (<p>{value.toFixed(2)}</p>)

            }, {
                title: 'Payment Status',
                key: 'payment_status',
                dataIndex: 'payment_status'

            }];
        let that = this;
        return <Content className="main-container" style={{minHeight: 280,}}>
            <Layout>
                <Switch>
                    <Route path={"/booking/bed-booking"}
                           render={() => <BedandMedicineBookingDynamicForm bedBooking={true} {...this.props}
                                                                           loadData={this.loadBedBookingReport}/>}/>
                    <Route path={"/booking/medicine-booking"}
                           render={() => <BedandMedicineBookingDynamicForm {...this.props} bedBooking={false}
                                                                           loadData={this.loadBedBookingReport}/>}/>
                    <Route>
                        <div>
                            <Row style={{paddingBottom: 10}}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Card hoverable key={'bed'}
                                          onClick={() => that.props.history.push("/booking/bed-booking")}>
                                        <Card.Meta
                                            title={<h2><Icon style={{fontSize: 30, paddingRight: 20}}
                                                             type={"box-plot"}/>Book Bed
                                            </h2>}
                                        /></Card>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Card hoverable key={'medicine'}
                                          onClick={() => that.props.history.push("/booking/medicine-booking")}>
                                        <Card.Meta
                                            title={<h2><Icon style={{fontSize: 30, paddingRight: 20}}
                                                             type={"medicine-box"}/>Book Medicine
                                            </h2>}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <h2>My Booking

                                {/*<Link to="/booking/bed-booking">*/}
                                {/*    <Button type="primary" style={{float: 'right'}}>*/}
                                {/*        <Icon type="plus"/>&nbsp;Book A Seat*/}
                                {/*    </Button>*/}
                                {/*</Link>*/}

                            </h2>
                            <Card>
                                <CustomizedTable pagination={false} hideReport={true}
                                                 loading={this.state.loading}
                                                 columns={columns}
                                                 dataSource={this.state.bedBookingReports}/>
                                <InfiniteFeedLoaderButton
                                    loaderFunction={() => this.loadBedBookingReport(this.state.nextReport)}
                                    loading={this.state.loading}
                                    hidden={!this.state.nextReport}/>
                            </Card>
                        </div>
                    </Route>
                </Switch>
            </Layout>
        </Content>

    }
}
