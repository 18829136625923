import React from "react";
import {Affix, Alert, Card, Col, Divider, Icon, Row, Statistic, Table, Tag, Tooltip} from "antd";
import {getAPI, interpolate} from "../../../utils/common";
import {DRUG_CATALOG, INVOICES_API, PROCEDURE_CATEGORY, TAXES} from "../../../constants/api";
import moment from "moment";
import {Link} from "react-router-dom";
import InfiniteFeedLoaderButton from "../../common/InfiniteFeedLoaderButton";

class PatientInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: this.props.currentPatient,
            active_practiceId: this.props.active_practiceId,
            invoices: [],
            drug_catalog: null,
            procedure_category: null,
            taxes_list: null,
            editInvoice: null,
            loading: true
        }
        this.loadInvoices = this.loadInvoices.bind(this);
        this.loadDrugCatalog = this.loadDrugCatalog.bind(this);
        this.loadProcedureCategory = this.loadProcedureCategory.bind(this);
        this.loadTaxes = this.loadTaxes.bind(this);
        this.editInvoiceData = this.editInvoiceData.bind(this);

    }

    componentDidMount() {
        this.loadInvoices();
        // this.loadDrugCatalog();
        // this.loadProcedureCategory()
        // this.loadTaxes();

    }

    loadInvoices() {
        let that = this;
        that.setState({
            loading: true
        });
        let successFn = function (data) {
            that.setState({
                invoices: data.results,
                loading: false,
                loadMoreInvoice: data.next
            })
        };
        let errorFn = function () {
            that.setState({
                loading: false
            })

        };
        let reqData={
            page:1,
            page_size:1,
        };
        getAPI(interpolate(INVOICES_API, [this.props.currentPatient.id]), successFn, errorFn, reqData);
    }

    loadDrugCatalog() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                drug_catalog: data,
            })

        }
        let errorFn = function () {

        }
        getAPI(interpolate(DRUG_CATALOG, [this.props.active_practiceId]), successFn, errorFn)
    }

    loadProcedureCategory() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                procedure_category: data,
            })

        }
        let errorFn = function () {


        }
        getAPI(interpolate(PROCEDURE_CATEGORY, [this.props.active_practiceId]), successFn, errorFn);
    }

    loadTaxes() {
        var that = this;
        let successFn = function (data) {
            console.log("get table");
            that.setState({
                taxes_list: data,
            })
        };
        let errorFn = function () {
        };
        getAPI(interpolate(TAXES, [this.props.active_practiceId]), successFn, errorFn);

    }

    editInvoiceData(record) {
        this.setState({
            editInvoice: record,
        });
        let id = this.props.match.params.id
        this.props.history.push("/patient/" + id + "/billing/invoices/edit")

    }

    render() {
        const drugs = {}
        if (this.state.drug_catalog) {

            this.state.drug_catalog.forEach(function (drug) {
                drugs[drug.id] = (drug.name + "," + drug.strength)
            })
        }
        const procedures = {}
        if (this.state.procedure_category) {
            this.state.procedure_category.forEach(function (procedure) {
                procedures[procedure.id] = procedure.name;
            })
        }

        const taxesdata = {}
        if (this.state.taxes_list) {
            this.state.taxes_list.forEach(function (tax) {
                taxesdata[tax.id] = tax.name;
            })
        }
        console.log(taxesdata)

        const columns = [{
            title: 'Time',
            dataIndex: 'created_at',
            key: 'name',
            render: created_at => <span>{moment(created_at).format('LLL')}</span>,
        }, {
            title: 'Treatment & Products',
            key: 'drug',
            render: (text, record) => (
                <span> <b>{record.inventory ? record.inventory_item_data.name : null}{record.procedure ? record.procedure_data.name : null}</b>
                    <br/> {record.doctor_data ?
                        <Tag color={record.doctor_data ? record.doctor_data.calendar_colour : null}>
                            <b>{"prescribed by  " + record.doctor_data.user.first_name} </b>
                        </Tag> : null}
                </span>

            )
        }, {
            title: 'Cost',
            dataIndex: 'unit_cost',
            key: 'cost',
            render: (item, record) => <span>{record.unit_cost * record.unit}</span>
        }, {
            title: 'Discount',
            dataIndex: 'unit_cost',
            key: 'cost',
            render: (item, record) => <span>{record.unit_cost * record.unit}</span>
        }, {
            title: 'Tax',
            dataIndex: 'unit_cost',
            key: 'cost',
            render: (item, record) => <span>{record.unit_cost * record.unit}</span>
        }, {
            title: 'Total',
            dataIndex: 'unit_cost',
            key: 'cost',
            render: (item, record) => <span>{record.unit_cost * record.unit}</span>
        },];
        let that = this;
        return <div>
            <Affix offsetTop={0}>
                <Card
                    bodyStyle={{padding: 0}}
                    title={"Latest Invoice"}>
                </Card>

            </Affix>
            {this.state.invoices.map(invoice => InvoiceCard(invoice, that))}
            {/*<InfiniteFeedLoaderButton loaderFunction={this.loadInvoices}*/}
            {/*                          loading={this.state.loading}*/}
            {/*                          hidden={!this.state.loadMoreInvoice}/>*/}

        </div>
    }
}

export default PatientInvoices;

function InvoiceCard(invoice, that) {
    let tableObjects = [];
    if (invoice.reservation) {
        let medicinesPackages = invoice.reservation_data.medicines.map(item => Object.create({
            ...item,
            unit: 1,
            total: item.final_price,
            unit_cost: item.price,
            discount: 0
        }));
        let mapper = {
            "NORMAL": {total: 'final_normal_price', tax: "normal_tax_value", unit_cost: "normal_price"},
            "TATKAL": {total: 'final_tatkal_price', tax: "tatkal_tax_value", unit_cost: "tatkal_price"}
        }
        console.log(mapper[invoice.reservation_data.seat_type].unit_cost);
        tableObjects = [...tableObjects, {
            ...invoice.reservation_data.bed_package,
            unit: 1,
            total: invoice.reservation_data.bed_package ? invoice.reservation_data.bed_package[mapper[invoice.reservation_data.seat_type].total] : null,
            tax_value: invoice.reservation_data.bed_package ? invoice.reservation_data.bed_package[mapper[invoice.reservation_data.seat_type].tax] : null,
            unit_cost: invoice.reservation_data.bed_package ? invoice.reservation_data.bed_package[mapper[invoice.reservation_data.seat_type].unit_cost] : null
        }, ...medicinesPackages]
    }
    return <Card
        key={invoice.id}
        style={{marginTop: 10}}
        bodyStyle={{padding: 0}}
        title={<small>{invoice.date ? moment(invoice.date).format('ll') : null}
            {that.state.currentPatient ? null : <span>
            <Link to={"/patient/" + (invoice.patient_data ? invoice.patient_data.id : null) + "/billing/invoices"}>
                &nbsp;&nbsp; {invoice.patient_data ? invoice.patient_data.user.first_name : null} (ID: {invoice.patient_data ? invoice.patient_data.id : null})&nbsp;
            </Link>, {invoice.patient_data ? invoice.patient_data.gender : null}</span>}
        </small>}>
        <Row gutter={8}>
            <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4} style={{padding: 10}}>
                {invoice.is_cancelled ?
                    <Alert message="Cancelled" type="error" showIcon/> : null}
                <Divider style={{marginBottom: 0}}>{invoice.invoice_id}</Divider>
                <Statistic title="Paid / Total "
                           value={(invoice.payments_data ? invoice.payments_data.toFixed(2) : 0)}
                           suffix={"/ " + invoice.total.toFixed(2)}/>
            </Col>
            <Col xs={24} sm={24} md={18} lg={20} xl={20} xxl={20}>
                {invoice.type == "Membership Amount." ?
                    <Table
                        bordered={true}
                        pagination={false}
                        columns={columns}
                        dataSource={[{
                            inventory: true,
                            name: "Membership",
                            unit_cost: invoice.total,
                            unit: 1,
                            discount_value: 0,
                            tax_value: 0,
                            total: invoice.total
                        }]}
                        footer={() => invoiceFooter({practice: invoice.practice_data})}/> :
                    <Table
                        bordered={true}
                        pagination={false}
                        columns={columns}
                        dataSource={[...tableObjects, ...invoice.inventory, ...invoice.procedure]}
                        footer={() => invoiceFooter({practice: invoice.practice_data})}/>}
            </Col>
        </Row>
    </Card>
}

const columns = [{
    title: 'Treatment & Products',
    dataIndex: 'drug',
    key: 'drug',
    render: (text, record) => (
        <span> <b>{record.name ? record.name : null}</b>
                    <br/> {record.doctor_data ?
                <Tag color={record.doctor_data ? record.doctor_data.calendar_colour : null}>
                    <b>{"prescribed by  " + record.doctor_data.user.first_name} </b>
                </Tag> : null}
                </span>)
}, {
    title: 'Cost',
    dataIndex: 'unit_cost',
    key: 'unit_cost',
    render: (item, record) => <span>{record.unit_cost ? record.unit_cost.toFixed(2) : null}</span>
}, {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
}, {
    title: 'Discount',
    dataIndex: 'discount_value',
    key: 'discount_value',
    render: (item, record) => <span>{record.discount_value ? record.discount_value.toFixed(2) : null}</span>
}, {
    title: 'Tax',
    dataIndex: 'tax_value',
    key: 'tax_value',
    render: (item, record) => <span>{record.tax_value ? record.tax_value.toFixed(2) : null}</span>
}, {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: item => item ? item.toFixed(2) : null
}];

function invoiceFooter(presc) {
    if (presc) {
        return <p>
            {presc.doctor ? <Tooltip title="Doctor"><Tag color={presc.doctor ? presc.doctor.calendar_colour : null}>
                <b>{"prescribed by  " + presc.doctor.user.first_name} </b>
            </Tag></Tooltip> : null}
            {presc.practice ? <Tag style={{float: 'right'}}>
                <Tooltip title="Practice Name">
                    <b>{presc.practice.name} </b>
                </Tooltip>
            </Tag> : null}
        </p>
    }
    return null
}
