import React from "react";
import {Button, Divider, Drawer, Icon, Layout, Menu} from "antd";
import {Link} from "react-router-dom";

const {Header, Content} = Layout;
const Sider = Layout.Sider;

class PatientSider extends React.Component {
    state = {
        collapsed: false,
        showDrawer: false
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    toggleDrawer = (value) => {
        this.setState({
            showDrawer: !!value
        })
    }

    render() {
        return <><Sider trigger={null}
                        collapsible
                        collapsed={this.props.collapsed}
                        style={{overflow: 'auto', minHeight: 'calc(100vh - 64px)', background: '#fff'}}>
            {/* <div className="logo"/> */}
            <Menu mode="inline">

                <Menu.ItemGroup key="g1" title={<Divider style={{margin: '0px'}}>Patient</Divider>}>
                    <Menu.Item key="17">
                        <Link
                            to={"/"}>
                            <Icon type="user"/>Profile
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="18">
                        <Link
                            to={"/patient/" + this.props.currentPatient.id + "/appointments"}>
                            <Icon type="calendar"/>Appointments
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="29" disabled={!this.props.currentPatient}>
                        <Link
                            to={this.props.currentPatient ? "/booking/" : "/patients/profile"}>
                            <Icon type="book"/>Booking
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item key="19" disabled={!this.props.currentPatient}>*/}
                    {/*<Link*/}
                    {/*to={"/communications"}>*/}
                    {/*<Icon type="message"/>Communications*/}
                    {/*</Link>*/}
                    {/*</Menu.Item>*/}
                </Menu.ItemGroup>
                <Menu.ItemGroup key="g2" title={<Divider style={{margin: '0px'}}>EMR</Divider>}>
                    {/*<SubMenu key="nestedsub1" title={<span>EMR</span>}>*/}
                    <Menu.Item key="20">
                        <Link
                            to={"/emr/vitalsigns"}>
                            <Icon type="heart"/>Manual Report</Link>
                    </Menu.Item>
                    <Menu.Item key="21">
                        <Link
                            to={"/emr/clinicnotes"}>
                            <Icon type="solution"/>Clinical Notes
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="30">
                        <Link
                            to={"/emr/plans"}>
                            <Icon type="read"/>Treatment Plans
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="22">
                        <Link
                            to={"/emr/workdone"}>
                            <Icon type="check-circle"/>Completed Procedure
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="23">
                        <Link
                            to={"/emr/files"}>
                            <Icon type="picture"/>Files
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="24">
                        <Link
                            to={"/emr/prescriptions"}>
                            <Icon type="solution"/>Prescriptions
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="25">
                        <Link
                            to={"/emr/timeline"}>
                            <Icon type="clock-circle"/>Timeline
                        </Link>
                    </Menu.Item>

                    {/*<Menu.Item key="25" disabled={!this.props.currentPatient}>*/}
                    {/*<Link*/}
                    {/*to={this.props.currentPatient ? "/patient/" + this.props.currentPatient.id + "/emr/timeline" : "/patients/profile"}>*/}
                    {/*<Icon type="clock-circle"/>Timeline*/}
                    {/*</Link>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="26" disabled={!this.props.currentPatient}>
                        <Link
                            to={"/emr/labtrackings"}>
                            <Icon type="solution"/>Lab Orders
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                {/*</SubMenu>*/}
                <Menu.ItemGroup key="g3" title={<Divider style={{margin: '0px'}}>Billing</Divider>}>
                    <Menu.Item key="27">
                        <Link
                            to={"/billing/invoices"}>
                            <Icon type="audit"/>Invoices
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="35">
                        <Link
                            to={"/billing/return/invoices"}>
                            <Icon type="redo"/>Invoices Return
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="28">
                        <Link
                            to={"/billing/payments"}>
                            <Icon type="dollar"/>Payments
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="29">
                        <Link
                            to={"/billing/ledger"}>
                            <Icon type="dollar"/>Ledger
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        </Sider>
            <div className={"responsive-drawer-menu"}>
                <Button icon={"menu"} size="large" style={{position: 'fixed', left: 0, top: 200, zIndex: 999}}
                        onClick={() => this.toggleDrawer(!this.state.showDrawer)}/>
                <Drawer visible={this.state.showDrawer} width={200} placement={"left"} bodyStyle={{padding: 0}} closable={false}
                        onClose={() => this.toggleDrawer(false)}>
                    <Menu mode="inline" onSelect={() => this.toggleDrawer(false)}>

                        <Menu.ItemGroup key="g1" title={<Divider style={{margin: '0px'}}>Patient</Divider>}>
                            <Menu.Item key="17">
                                <Link
                                    to={"/"}>
                                    <Icon type="user"/>Profile
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="18">
                                <Link
                                    to={"/patient/" + this.props.currentPatient.id + "/appointments"}>
                                    <Icon type="calendar"/>Appointments
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="book" disabled={!this.props.currentPatient}>
                                <Link
                                    to={this.props.currentPatient ? "/booking/" : "/patients/profile"}>
                                    <Icon type="book"/>Booking
                                </Link>
                            </Menu.Item>
                            {/*<Menu.Item key="19" disabled={!this.props.currentPatient}>*/}
                            {/*<Link*/}
                            {/*to={"/communications"}>*/}
                            {/*<Icon type="message"/>Communications*/}
                            {/*</Link>*/}
                            {/*</Menu.Item>*/}
                        </Menu.ItemGroup>
                        <Menu.ItemGroup key="g2" title={<Divider style={{margin: '0px'}}>EMR</Divider>}>
                            {/*<SubMenu key="nestedsub1" title={<span>EMR</span>}>*/}
                            <Menu.Item key="20">
                                <Link
                                    to={"/emr/vitalsigns"}>
                                    <Icon type="heart"/>Manual Report</Link>
                            </Menu.Item>
                            <Menu.Item key="21">
                                <Link
                                    to={"/emr/clinicnotes"}>
                                    <Icon type="solution"/>Clinical Notes
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="30">
                                <Link
                                    to={"/emr/plans"}>
                                    <Icon type="read"/>Treatment Plans
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="22">
                                <Link
                                    to={"/emr/workdone"}>
                                    <Icon type="check-circle"/>Completed Procedure
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="23">
                                <Link
                                    to={"/emr/files"}>
                                    <Icon type="picture"/>Files
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="24">
                                <Link
                                    to={"/emr/prescriptions"}>
                                    <Icon type="solution"/>Prescriptions
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="25">
                                <Link
                                    to={"/emr/timeline"}>
                                    <Icon type="clock-circle"/>Timeline
                                </Link>
                            </Menu.Item>

                            {/*<Menu.Item key="25" disabled={!this.props.currentPatient}>*/}
                            {/*<Link*/}
                            {/*to={this.props.currentPatient ? "/patient/" + this.props.currentPatient.id + "/emr/timeline" : "/patients/profile"}>*/}
                            {/*<Icon type="clock-circle"/>Timeline*/}
                            {/*</Link>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item key="26" disabled={!this.props.currentPatient}>
                                <Link
                                    to={"/emr/labtrackings"}>
                                    <Icon type="solution"/>Lab Orders
                                </Link>
                            </Menu.Item>
                        </Menu.ItemGroup>
                        {/*</SubMenu>*/}
                        <Menu.ItemGroup key="g3" title={<Divider style={{margin: '0px'}}>Billing</Divider>}>
                            <Menu.Item key="27">
                                <Link
                                    to={"/billing/invoices"}>
                                    <Icon type="audit"/>Invoices
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="35">
                                <Link
                                    to={"/billing/return/invoices"}>
                                    <Icon type="redo"/>Invoices Return
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="28">
                                <Link
                                    to={"/billing/payments"}>
                                    <Icon type="dollar"/>Payments
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="29">
                                <Link
                                    to={"/billing/ledger"}>
                                    <Icon type="dollar"/>Ledger
                                </Link>
                            </Menu.Item>
                        </Menu.ItemGroup>
                    </Menu>
                </Drawer>
            </div>
        </>

    }
}

export default PatientSider;

