import {Button, Card, Checkbox, Divider, Icon, Table, Tooltip, Menu, Col, Row, Tag, Spin} from "antd";
import React from "react";
import {getAPI, interpolate} from "../../../utils/common";
import {PATIENT_CLINIC_NOTES_API,CLINIC_NOTES_PDF} from "../../../constants/api";
import moment from "moment";
import {Link, Redirect} from "react-router-dom";
import {CUSTOM_STRING_SEPERATOR} from "../../../constants/hardData";
import InfiniteFeedLoaderButton from "../../common/InfiniteFeedLoaderButton";
import {BACKEND_BASE_URL} from "../../../config/connect";

class PatientClinicNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: this.props.currentPatient,
            active_practiceId: this.props.active_practiceId,
            clinicNotes: [],
            editClinicNotes: null,
            loading: true
        }
        this.loadClinicNotes = this.loadClinicNotes.bind(this);
    }
    componentDidMount() {
        this.loadClinicNotes();
    }
    loadClinicNotes() {
        let that = this;
        let successFn = function (data) {
            that.setState(function (prevState) {
                return {
                    clinicNotes: [...prevState.clinicNotes, ...data.results],
                    next: data.next,
                    loading: false
                }
            })
        }
        let errorFn = function () {
            that.setState({
                loading: false
            })

        }
        getAPI(interpolate(PATIENT_CLINIC_NOTES_API, [this.props.currentPatient.id]), successFn, errorFn)
    }
    loadPDF(id) {
        let that = this;
        let successFn = function (data) {
            if (data.report)
                window.open(BACKEND_BASE_URL + data.report);
        }
        let errorFn = function () {

        }
        getAPI(interpolate(CLINIC_NOTES_PDF, [id]), successFn, errorFn);
    }
    render() {
        let that =this;
        const columns = [{
            title: 'Time',
            dataIndex: 'created_at',
            key: 'name',
            render: created_at => <span>{moment(created_at).format('LLL')}</span>,
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Chief complaints',
            dataIndex: 'chief_complaints',
            key: 'chief_complaints',
        }, {
            title: 'Investigations',
            dataIndex: 'investigations',
            key: 'investigations',
        }, {
            title: 'Diagnosis',
            dataIndex: 'diagnosis',
            key: 'diagnosis',
        }, {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
        }, {
            title: 'Observations',
            dataIndex: 'observations',
            key: 'observations',
        },];
            return <div>
                    <Card bodyStyle={{padding: 0}}
                            title={this.state.currentPatient ? this.state.currentPatient.user.first_name + " ClinicNotes" : "ClinicNotes"}>

                        {/*<Table loading={this.state.loading} columns={columns} dataSource={this.state.clinicNotes}/>*/}

                    </Card>
                        {this.state.clinicNotes.map(clinicNote => <Card style={{marginTop: 20}}>
                            <div>
                                <h4>{clinicNote.date ? moment(clinicNote.date).format('ll') : null}
                                </h4>
                                <Divider style={{margin: 0}}/>
                                <Row>
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                        <h3>Complaints</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={18} lg={18} style={{borderLeft: '1px solid #ccc', padding: 4}}>
                                        <div style={{minHeight: 30}}>
                                            {clinicNote.chief_complaints ? clinicNote.chief_complaints.split(CUSTOM_STRING_SEPERATOR).map(str =>
                                                <span>{str}<br/></span>) : null}
                                        </div>
                                        <Divider style={{margin: 0}}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={24} sm={24} md={6} lg={6}>
                                        <h3>Observations</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={18} lg={18} style={{borderLeft: '1px solid #ccc', padding: 4}}>
                                        <div style={{minHeight: 30}}>
                                            {clinicNote.observations ? clinicNote.observations.split(CUSTOM_STRING_SEPERATOR).map(str =>
                                                <span>{str}<br/></span>) : null}
                                        </div>
                                        <Divider style={{margin: 0}}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={24} sm={24} md={6} lg={6}>
                                        <h3>Investigations</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={18} lg={18} style={{borderLeft: '1px solid #ccc', padding: 4}}>
                                        <div style={{minHeight: 30}}>
                                            {clinicNote.investigations ? clinicNote.investigations.split(CUSTOM_STRING_SEPERATOR).map(str =>
                                                <span>{str}<br/></span>) : null}
                                        </div>
                                        <Divider style={{margin: 0}}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={24} sm={24} md={6} lg={6}>
                                        <h3>Diagnoses</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={18} lg={18} style={{borderLeft: '1px solid #ccc', padding: 4}}>
                                        <div style={{minHeight: 30}}>
                                            {clinicNote.diagnosis ? clinicNote.diagnosis.split(CUSTOM_STRING_SEPERATOR).map(str =>
                                                <span>{str}<br/></span>) : null}
                                        </div>
                                        <Divider style={{margin: 0}}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={24} sm={24} md={6} lg={6}>
                                        <h3>Notes</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={18} lg={18} style={{borderLeft: '1px solid #ccc', padding: 4}}>
                                        <div style={{minHeight: 30}}>
                                            {clinicNote.notes ? clinicNote.notes.split(CUSTOM_STRING_SEPERATOR).map(str =>
                                                <span>{str}<br/></span>) : null}
                                        </div>
                                        <Divider style={{margin: 0}}/>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                {clinicNote.doctor ?
                                    <Tag color={clinicNote.doctor ? clinicNote.doctor.calendar_colour : null}>
                                        <b>{"prescribed by  " + clinicNote.doctor.user.first_name} </b>
                                    </Tag> : null}
                                    {clinicNote.practice ? <Tag style={{float: 'right'}}>
                                    <Tooltip title="Practice Name">
                                        <b>{clinicNote.practice.name} </b>
                                    </Tooltip>
                                </Tag> : null}
                            </div>
                        </Card>)}
                        <Spin spinning={this.state.loading}>
                            <Row/>
                        </Spin>
                        <InfiniteFeedLoaderButton loaderFunction={() => this.loadClinicNotes(that.state.next)}
                                                  loading={this.state.loading}
                                                  hidden={!this.state.next}/>
            </div>
       

    }
}
export default PatientClinicNotes;
