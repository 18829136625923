import React from "react";
import {Button, Card, Tag, Menu, Icon, Table, Tooltip} from "antd";
import {getAPI, interpolate} from "../../../utils/common";
import {PROCEDURE_CATEGORY, PRODUCT_MARGIN,TREATMENTPLANS_PDF, TREATMENTPLANS_API} from "../../../constants/api";
import moment from "moment";
import InfiniteFeedLoaderButton from "../../common/InfiniteFeedLoaderButton";
import {BACKEND_BASE_URL} from "../../../config/connect";


class PatientCompletedProcedures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: this.props.currentPatient,
            active_practiceId: this.props.active_practiceId,
            treatmentPlans: [],
            procedure_category: null,
            completedTreatmentPlans: [],
            productMargin: [],
            loading:true
        }
        this.loadTreatmentPlans = this.loadTreatmentPlans.bind(this);
        this.loadProcedureCategory = this.loadProcedureCategory.bind(this);
    }

    componentDidMount() {
            this.loadTreatmentPlans();
            // this.loadProcedureCategory();
            this.loadProductMargin();
    }

    loadProductMargin() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                productMargin: data,
                loading:false
            })
        }
        let errorFn = function () {
            that.setState({
                loading:false
            })
        }
        getAPI(PRODUCT_MARGIN, successFn, errorFn);
    }

    loadTreatmentPlans() {
        let completed = [];
        let that = this;
        let successFn = function (data) {
            that.setState(function (prevState) {
                return {
                    treatmentPlans: [...prevState.treatmentPlans, ...data.results],
                    next: data.next,
                    loading: false
                }
            })
            data.results.forEach(function (treatmentplan) {
                if (treatmentplan.is_completed) {
                    completed.push(treatmentplan)
                }
            })
            that.setState(function (prevState) {
                return {
                    completedTreatmentPlans: [...prevState.completedTreatmentPlans, ...completed],
                    loading: false
                }
            })

        }
        let errorFn = function () {
            that.setState({
                loading:false
            })
        }
        getAPI(interpolate(TREATMENTPLANS_API, [this.props.currentPatient.id,true]), successFn, errorFn)
    }

    loadProcedureCategory() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                procedure_category: data,
                loading:false
            })
        };
        let errorFn = function () {
            that.setState({
                loading:false
            })

        }
        getAPI(interpolate(PROCEDURE_CATEGORY, [this.props.active_practiceId]), successFn, errorFn)
    }
    loadPDF(id) {
        let that = this;
        let successFn = function (data) {
            if (data.report)
                window.open(BACKEND_BASE_URL + data.report);
        }
        let errorFn = function () {

        }
        getAPI(interpolate(TREATMENTPLANS_PDF, [id]), successFn, errorFn);
    }

    render() {
        let that=this;
        const procedures = {}
        if (this.state.procedure_category) {
            this.state.procedure_category.forEach(function (procedure) {
                procedures[procedure.id] = (procedure.name)
            })

        }

        const columns = [{
            title: 'Procedure',
            key: 'procedure',
            dataIndex:'procedure.name'
        }, {
            title: 'Quantity',
            dataIndex: 'quantity',
            initialValue: (this.state.editFields ? this.state.editFields.quantity : null),
            key: 'quantity',
        }, {
            title: 'Cost Per  Unit',
            dataIndex: 'cost',
            initialValue: (this.state.editFields ? this.state.editFields.cost : null),
            key: 'cost',
        }];
            return <div>
                    <Card
                        bodyStyle={{padding: 0}}
                        title={this.state.currentPatient ? this.state.currentPatient.user.first_name + " Completed Procedures" : "Completed Procedures "}>
                    </Card>
                    {this.state.treatmentPlans.map((treatment) => <Card bodyStyle={{padding: 0}}
                                                                        style={{marginTop: 15}}>
                            <div style={{padding: 16}}>
                                <h4>{treatment.date ? moment(treatment.date).format('ll') : null}
                                    <Button
                                        size={"small"}
                                        style={{float: 'right'}}>
                                        <a onClick={() => this.loadPDF(treatment.id)}><Icon type="printer"/></a>

                                    </Button>
                                </h4>

                            </div>
                            <Table loading={this.state.loading} columns={columns}
                                dataSource={treatment.treatment_plans}
                                footer={() => treatmentFooter(treatment)}
                                pagination={false}
                                key={treatment.id}/>

                        </Card>
                    )}
                    <InfiniteFeedLoaderButton loaderFunction={() => this.loadTreatmentPlans(that.state.next)}
                                            loading={this.state.loading}
                                            hidden={!this.state.next}/>
                </div>

            // <div>
            //     <Card
            //         title={this.state.currentPatient ? this.state.currentPatient.user.first_name + " Completed Procedures" : "Completed Procedures "}>

            //         {this.state.treatmentPlans.map((treatment) =>
            //             <Table loading={this.state.loading} columns={columns} dataSource={treatment.treatment_plans} pagination={false}/>)}

            //     </Card>
            // </div>

    }
}

export default PatientCompletedProcedures;
function treatmentFooter(presc) {
    if (presc) {

        return <div>
            {presc.doctor ? <Tag color={presc.doctor ? presc.doctor.calendar_colour : null}>
                <b>{"prescribed by  " + presc.doctor.user.first_name} </b>
            </Tag> : null}
            {presc.practice ? <Tag style={{float: 'right'}}>
                <Tooltip title="Practice Name">
                    <b>{presc.practice.name} </b>
                </Tooltip>
            </Tag> : null}
        </div>
    }
    return null
}
