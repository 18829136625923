import React from "react";
import {Button, Card, Col, Icon, List, Modal, Radio, Row,Dropdown,Tag} from "antd";
import {displayMessage, getAPI, interpolate, makeFileURL} from "../../../utils/common";
import {ALL_PATIENT_FILES,MEDICAL_CERTIFICATE_API, EMR_FILETAGS, PATIENT_FILES} from "../../../constants/api";
import DynamicFieldsForm from "../../common/DynamicFieldsForm";
import {Form} from "antd/lib/index";
import Meta from "antd/lib/card/Meta";
import {
    INFO_MSG_TYPE,
    MULTI_SELECT_FIELD,
    SINGLE_IMAGE_UPLOAD_FIELD,
    SUCCESS_MSG_TYPE, WARNING_MSG_TYPE
} from "../../../constants/dataKeys";
import ModalImage from "react-modal-image";
import {Redirect, Link} from 'react-router-dom';

class PatientFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            tags: [],
            filterSearchTag: null,
            showAddModal: false,
            loading: true,
        };
        this.loadData = this.loadData.bind(this);
        this.loadFileUploaded = this.loadFileUploaded.bind(this);
        this.loadMedicalCertificate = this.loadMedicalCertificate.bind(this);
    }

    componentWillMount() {
        this.loadData();
        // this.loadTags();
        this.loadFileUploaded();
        this.loadMedicalCertificate();
    }

    loadFileUploaded(){
        let that=this;
        let successFn =function (data) {
            that.setState({
                patientFileCount:data.count,
                patientFile:data.results
            })

        };
        let errorFn=function () {

        };
        let apiParams={
            upload_by:'PATIENT',

        };
        getAPI(interpolate(PATIENT_FILES, [this.props.currentPatient.id]),successFn, errorFn, apiParams);
    }

    loadData(page = 1) {
        let that = this;
        this.setState({
            loading:true
        })
        let successFn = function (data) {
            that.setState(function (prevState) {
                if (data.current == 1) {
                    return {
                        files: [...data.results],
                        loading: false
                    }
                } else {
                    return {
                        files: [...prevState.files, ...data.results],
                        loading: false
                    }
                }

            })
        }
        let errorFn = function () {
            that.setState({
                loading: false
            })
        }
        let apiParams = {
            page: page,
        };

        if (this.props.match.params.id) {
            apiParams.patient = this.props.match.params.id;
        }
        if (this.props.showAllClinic && this.props.match.params.id) {
            delete (apiParams.practice)
        }
        if (this.state.filterSearchTag) {
            apiParams.tag = this.state.filterSearchTag
        } else if (this.state.filterSearchTag == '') {
            apiParams.notag = true
        }
        getAPI(interpolate(PATIENT_FILES, [this.props.currentPatient.id]),successFn, errorFn, apiParams);

    }

    loadTags() {
        let that = this;
        let successFn = function (data) {
            that.setState({
                tags: data,
                loading: false
            })
        }
        let errorFn = function () {
            that.setState({
                loading: false
            })
        }
        getAPI(interpolate(EMR_FILETAGS, [that.props.active_practiceId]), successFn, errorFn);
    }

    loadMedicalCertificate =()=>{
        let that=this;
        let successFn = function (data){
            that.setState({
                medicalCertificate:data.results,
            });
        }
        let errorFn = function(){

        }
        getAPI(interpolate(MEDICAL_CERTIFICATE_API, [this.props.currentPatient.id]),successFn, errorFn);
    }

    filterTags = (e) => {
        let that = this;
        this.setState({
            filterSearchTag: e.target.value
        }, function () {
            that.loadData();
        })
    }

    triggerAddModal(option) {
        let that=this;
        if (that.props.currentPatient.file_enable && that.props.currentPatient.file_count<=that.state.patientFileCount){
            displayMessage(WARNING_MSG_TYPE, "File Upload Not Allowed. Please Contact to Clinic!!.");

        }else {
            this.setState({
                showAddModal: !!option
            },function () {
                that.loadFileUploaded();
            })
        }


    }
    
    render() {
        let that = this;
        const PatientFilesForm = Form.create()(DynamicFieldsForm);
        const fields = [{
            key: 'file_type',
            label: 'File',
            type: SINGLE_IMAGE_UPLOAD_FIELD
        }, {
            key: 'file_tags',
            label: 'Tags',
            type: MULTI_SELECT_FIELD,
            options: this.state.tags.map(tag => ({label: tag.name, value: tag.id}))
        }];
        const formProps = {
            method: 'post',
            successFn: function () {
                that.triggerAddModal(false);
                that.loadData();
                that.loadFileUploaded();
            },
            errorFn: function () {
            },

            action: interpolate(PATIENT_FILES, [this.props.currentPatient.id])
        };
      
        
        const defaultFields = [{key: 'is_active', value: true}, {key: 'patient', value: this.props.currentPatient.id}, {key:'upload_by' ,value:'PATIENT'}]
        return <Card title="Files" extra={<Button.Group>
                        <Button onClick={() => this.triggerAddModal(true)}><Icon type="plus"/>Add</Button>
                    </Button.Group>}>
                <Row>
                    <Col xs={0} sm={0} md={6} lg={6}
                        style={{
                            height: 'calc(100vh - 55px)',
                            overflow: 'auto',
                            paddingRight: '10px',
                            //  backgroundColor: '#e3e5e6',
                            borderRight: '1px solid #ccc'
                        }}>
                        <Radio.Group buttonStyle="solid" defaultValue="all" onChange={this.filterTags}>
                            <h2>Uploaded Files</h2>
                            <Radio.Button style={{width: '100%', backgroundColor: 'transparent', border: '0px'}}
                                        value={null}>
                                All Files</Radio.Button>
                            {this.state.tags.map(tag => <Radio.Button
                                style={{width: '100%', backgroundColor: 'transparent', border: '0px'}} value={tag.id}>
                                {tag.name}
                            </Radio.Button>)}

                            <Radio.Button style={{width: '100%', backgroundColor: 'transparent', border: '0px'}} value="">
                                Untagged Files
                            </Radio.Button>
                            <p><br/></p>
                        
                        </Radio.Group>
                        
                        <Radio.Group buttonStyle="solid" defaultValue="">
                            <h2>Generated Files</h2>
                            <Radio.Button style={{width: '100%', backgroundColor: 'transparent', border: '0px'}} value="d" >
                                Emailed Files
                            </Radio.Button>

                            <Radio.Button style={{width: '100%', backgroundColor: 'transparent', border: '0px'}} onClick={this.loadMedicalCertificate}> 
                            Medical Leave Certificate </Radio.Button>
                        </Radio.Group>
                    </Col>
                <Col xs={24} sm={24} md={18} lg={18}>
                    <List loading={this.state.loading}
                          grid={{gutter: 16, column: 3}}
                          dataSource={this.state.files}
                          renderItem={item => (
                              <List.Item style={{textAlign: 'center'}}>
                                <ModalImage
                                    small={makeFileURL(item.file_type)}
                                    large={makeFileURL(item.file_type)}
                                    // alt={item.id}
                                />
                              </List.Item>
                          )}
                    />
                    <Card title="MedicalCertificate">
                            <List loading={this.state.loading}
                                grid={{gutter:16,column:3}}
                                dataSource={this.state.medicalCertificate}
                                renderItem={item =>(
                                <Card style={{ width: 600 }}>
                                    <Meta title={"Medical Leave #"+ "" + item.id} 
                                    description={"Excused From :" +item.excused_duty_from + " till " + item.excused_duty_to}>
                                    
                                    </Meta>
                                </Card>
                                )}
                                />
                        </Card>
                </Col>

                    <Modal visible={this.state.showAddModal}
                           onCancel={() => this.triggerAddModal(false)}
                           footer={null}>
                        <PatientFilesForm title="Add Files"
                                          fields={fields}
                                          defaultValues={defaultFields}
                                          formProp={formProps}/>
                    </Modal>
            </Row>
            
            
        </Card>
    }
}

export default PatientFiles;
